import React from 'react'


const ViewModal = ({ isOpen,
    title,
    description,
    image,
    onCancel, title1 }) => {
    if (!isOpen) return null
    return (
        <div>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
               

                    <div className="container">
                        <div className="svg-background" />
                        <div className="svg-background2" />
                        <div className="circle" />
                        <img
                            className="menu-icon curser-pointer"
                            src="/Frame 1000004669.png"
                            alt=''
                            onClick={onCancel}

                        />
                        <img
                            className="profile-img"
                            src={image}
                            alt='user'
                        />
                        <div className="text-container capitalize">
                            <p className="title-text">{title}</p>
                            <p className="info-text">{title1}</p>
                            <p className="desc-text">
                                {description}
                            </p>
                        </div>
                    </div>


                </div>
            </div>
        // </div>
    )
}

export default ViewModal