import React from 'react'

const UserPaymentDetails = ({ transactions, onClose }) => {
    const tableHeaders = ["#", "Transaction ID", "Payment Type", "User Name", "Date & Time", "Status", "Amount",];
    return (
        <div>
            <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-5xl">
                    {/* Header */}
                    <div className="flex justify-between items-center border-b p-4">
                        <h2 className="text-lg font-semibold">{transactions?.Enrolled}</h2>
                        <button
                            onClick={onClose}
                            className="text-gray-500 hover:text-gray-700 focus:outline-none"
                        >
                            &#10005;
                        </button>
                    </div>

                    {/* Table */}
                    <div className="overflow-x-auto">
                        <table className="w-full table-auto text-sm text-left">
                            {/* Table Head */}
                            <thead className="bg-[#F1F5F9] text-gray-600">
                                <tr>
                                    {tableHeaders.map((header, index) => (
                                        <th key={index} className="px-4 py-2">
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody>
                                {/* {transactions.map((item, index) => (
                                    <tr key={index} className="border-b hover:bg-gray-50">
                                        <td className="px-4 py-2">{index + 1}</td>
                                        <td className="px-4 py-2 font-medium text-blue-600">#{item.id}</td>
                                        <td className="px-4 py-2">{item.paymentType}</td>
                                        <td className="px-4 py-2">{item.userName}</td>
                                        <td className="px-4 py-2">{item.dateTime}</td>
                                        <td className="px-4 py-2">
                                            <span
                                                className={`px-2 py-1 rounded-full text-xs font-medium ${item.status === "Successful"
                                                        ? "bg-green-100 text-green-700"
                                                        : item.status === "Refunded"
                                                            ? "bg-blue-100 text-blue-700"
                                                            : "bg-red-100 text-red-700"
                                                    }`}
                                            >
                                                {item.status}
                                            </span>
                                        </td>
                                        <td className="px-4 py-2">{item.amount}</td>
                                    </tr>
                                ))} */}
                            </tbody>
                        </table>
                    </div>

                    <div className="flex justify-end space-x-3  border-t p-4">
                        <button
                            className="px-6 py-2 border border-[#B8B8D2] text-gray-700 rounded hover:bg-gray-300"
                            onClick={onClose}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-6 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
                           
                        >
                            Export PDF
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default UserPaymentDetails