import React, { useEffect, useRef } from 'react'
import moment from 'moment';
import { MdOutlineRemoveRedEye } from 'react-icons/md';


const SessionMangmentTable = ({ TbaleName, TbaleHeader, TbaleData, showMenu, viewAction, removeContent, isMenu, clickedUserId }) => {
    const ContentActionRef = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ContentActionRef.current && !ContentActionRef.current.contains(event.target)) {
                showMenu(null); 
            }
        };


        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);
    return (
        <div>
            <div>
                <div className=" bg-white rounded-md shadow-md">
                    <h2 className="text-xl font-bold inter500 p-[18px]">{TbaleName}</h2>
                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse">
                            <thead className='bg-[#F1F5F9] '>
                                <tr>
                                    {
                                        TbaleHeader.map((heading) => (
                                            <>
                                                <td className='py-3 px-4 capitalize inter500 '>{heading}</td>
                                            </>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {TbaleData.map((ele) => (
                                    <tr key={ele.id} className="hover:bg-gray-100">
                                        <td className="py-2 ps-6 border-b">{ele.id}</td>
                                        <td className="py-2 ps-5 border-b inter500 text-[#0F172A]">
                                            <div className=' gap-3 flex justify-start items-center'>
                                                <span className='w-20 h-14'>
                                                    <img src={ele?.image} alt='img' className='w-auto'/>
                                                </span>
                                                {ele.sessionName}
                                            </div>

                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">{ele.expertName}</td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">{ele.Enrolled}</td>
                                        <td
                                            className='py-2 px-4 border-b '
                                        >
                                            <button className={`px-4 py-1  `}>
                                                {ele?.duration}
                                            </button>
                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">{moment(ele.createdAt).format('YYYY-MM-DD ')}</td>


                                        <td className="py-2 px-4 border-b ">
                                            <div className=' gap-2 relative'>
                                                <button className='bg-[#E5E5EA] p-2 rounded flex justify-center ' onClick={() =>viewAction(ele)}>
                                                    <MdOutlineRemoveRedEye className='text-lg' />
                                                </button>
                                               
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div >
            </div>
        </div>
    )
}

export default SessionMangmentTable

