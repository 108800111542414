import React, { useState } from 'react'
import Button from '../../components/Button/Button'
import CertificateTable from './Table/CertificateTable';
import CertificateTemplete from './Template/CertificateTemplete';
import StatusUpdateModal from './Modal/StatusUpdateModal';

const CertificateMangment = () => {
    const [activeTab, setActiveTab] = useState('Certificates');
    const [isMenu, setIsMenu] = useState(false)
    const [isStatusModal,setIsStatusModal]=useState(false)
    const [statusData,setStatusData]=useState({
        campaignContent:""
    })
    const [clickedUserId, setClickedUserId] = useState(null)
    const tableHeader = ['#', 'Certificate & ID', 'Learner Name', 'Status', 'Completion Date', 'Issue Date', 'Action']
    const tableData = [
        { id: 1, image: "https://static.vecteezy.com/system/resources/thumbnails/006/425/314/small_2x/modern-university-certificate-template-free-vector.jpg", cId: "C1234", Lname: "Pankaj", status: true, date: "01-Dec-2004", issueDate: "20-Oct-2004" },
        { id: 2, image: "https://static.vecteezy.com/system/resources/thumbnails/006/425/314/small_2x/modern-university-certificate-template-free-vector.jpg", cId: "C1234", Lname: "Pankaj", status: true, date: "01-Dec-2004", issueDate: "20-Oct-2004" },
    ]
    const showMenu = (userId) => {
        if (clickedUserId === userId) {
            setIsMenu(!isMenu);
        } else {
            setClickedUserId(userId);
            setIsMenu(true);
        }
    };
    const handleEditCertificate=(data)=>{
        console.log(data)
    }
    const handleChangeStatus=(data)=>{
        setIsStatusModal(!isStatusModal)
        setStatusData(data)
    }
    const toggleStatusModal=()=>{
        setIsStatusModal(!isStatusModal)
    }
    const hadnleStatusOnChange=(e)=>{const {name,value}=e.target;setStatusData({...statusData,[name]:value})}

    return (
        <div>
            <div className="bg-gradient-to-r from-[#855DF6] to-[#715EFE] py-8  shadow-lg  relative flex items-center">
                <h2 className="text-2xl font-bold  text-white dahboardFont px-7 ">Certification Management</h2>

            </div>
            <section className='p-8'>
                <div className="flex relative space-x-3 h-10 w-full rounded-t-md border border-t-0 border-x-0 border-b-2 cursor-pointer ">
                    <Button
                        label="Certificates"
                        isActive={activeTab === "Certificates"}
                        onClick={() => setActiveTab("Certificates")}
                    />
                    <Button
                        label="Certificate Template"
                        isActive={activeTab === "CertificateTemplate"}
                        onClick={() => setActiveTab("CertificateTemplate")}
                    />

                </div>
                <div className='mt-5'>
                    {
                        activeTab === 'Certificates' && (
                            <CertificateTable TableName={`Certificates`} TableHeader={tableHeader} TableData={tableData} showMenu={showMenu} clickedUserId={clickedUserId} isMenu={isMenu} editAction={handleEditCertificate} handleChangeStatus={handleChangeStatus}/>
                        )
                    }
                    {
                        activeTab === 'CertificateTemplate' && (
                            <CertificateTemplete />
                        )
                    }

                </div>
            </section>
            {
                isStatusModal&&(
                    <StatusUpdateModal inputData={statusData} onCancel={toggleStatusModal} handleONChange={hadnleStatusOnChange}/>
                )
            }
        </div>
    )
}

export default CertificateMangment