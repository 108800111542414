
import React from 'react'
import { CiFilter } from 'react-icons/ci'
import { LiaEdit } from 'react-icons/lia'
import { MdOutlineRemoveRedEye } from 'react-icons/md'

const PromotationTable = ({ TableName, TableHeader, TableData, editAction, viewAction, handleFilter, handleSearch, handleCreateAds, total, pageNo, size, isMenu, clickedUserId
}) => {
    return (
        <div>
            <div>
                <div className="bg-white rounded-md shadow-md">
                    <div className="w-full flex justify-between place-content-center">
                        <h2 className="text-xl font-bold inter500 p-6">
                            {TableName}
                        </h2>
                        <div className="flex justify-between place-items-center gap-2 mx-2">
                            <input
                                type="text"
                                placeholder="Search...."
                                className="border h-10 px-2 rounded shadow"
                                onChange={handleSearch}
                            />
                            <div className="relative inline-flex items-center border rounded-md shadow bg-white">
                                <div className="px-3 py-2">
                                    <CiFilter className="text-lg" />
                                </div>
                                <select className="appearance-none w-full px-3 py-2 bg-transparent border-none focus:ring-0 focus:outline-none" onClick={handleFilter}>
                                    <option value="" disabled>
                                        Filter
                                    </option>
                                    <option value="">Filter</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="INACTIVE">Inactive</option>
                                    <option value="BLOCK">Block</option>
                                </select>

                            </div>
                            <button className="ml-2 border px-3 py-2 rounded-md bg-[#715EFE] text-white hover:bg-blue-600" onClick={handleCreateAds}>
                                + Generate New Code
                            </button>
                        </div>
                    </div>

                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse">
                            <thead className="bg-[#F1F5F9]">
                                <tr className="">
                                    {TableHeader?.map((heading, index) => (
                                        <td
                                            key={index}
                                            className="py-3 px-4 capitalize inter500 "
                                        >
                                            {heading}
                                        </td>
                                    ))}
                                </tr>
                            </thead>

                            <tbody>
                                {TableData && TableData.length > 0 ? (
                                    TableData?.map((learner, index) => (
                                        <tr key={index} className="hover:bg-gray-100">
                                            <td className="py-2 ps-6 border-b">
                                                {learner?.id}
                                            </td>
                                            <td className="py-2 ps-5 border-b inter500 text-[#0F172A]">


                                                <p className="capitalize">{learner.code}</p>

                                            </td>
                                            <td className="py-2 px-4 border-b text-[#475569] ">
                                                {learner.type}
                                            </td>
                                            <td className="py-2 px-4 border-b ">
                                                <button
                                                    className={`px-4 py-1 rounded-full ${learner.isBlocked
                                                        ? "bg-[#FF3B3033]/20 text-[#FF3B30]"
                                                        : learner.status
                                                            ? "bg-[#CDF3E5] text-[#43A581]"
                                                            : "bg-[#FFF0D4] text-[#FBB000]"
                                                        }`}
                                                >
                                                    {learner.isBlocked
                                                        ? "Blocked"
                                                        : learner.status
                                                            ? "Active"
                                                            : "Inactive"}
                                                </button>
                                            </td>
                                            <td className="py-2 px-4 border-b text-[#475569] ">
                                                {learner?.usageLimit}
                                            </td>
                                            <td className="py-2 px-4 border-b text-[#475569] ">
                                                {learner?.charges}
                                            </td>


                                            <td className="py-2 px-4 border-b relative">
                                                <div className=' gap-2 relative flex justify-start items-center'>
                                                    <button className='bg-[#E5E5EA] p-2 rounded flex justify-center ' onClick={() => viewAction(learner)}>
                                                        <MdOutlineRemoveRedEye className='text-lg' />
                                                    </button>
                                                    <button className='bg-[#E5E5EA] p-2 rounded flex justify-center ' onClick={() => editAction(learner)}>
                                                        <LiaEdit  className='text-lg' />
                                                    </button>
                                                    

                                                </div>
                                            </td>
                                        </tr>
                                    ))
                                ) : (
                                    <tr>
                                        <td colSpan="8" className="py-4 text-center text-[#475569]">
                                            No data found.
                                        </td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>



                </div>
            </div>
        </div>
    )
}

export default PromotationTable
