import React from "react";
import { CiFilter } from "react-icons/ci";
import moment from "moment";
import { BsThreeDotsVertical } from "react-icons/bs";

const LearningMangmentTable = ({TableName,TableHeader, TableData, handleEdit,handleUserBlock,handleFilter,handleSearch,showMenu,total,pageNo,size,isMenu,clickedUserId
}) => {



    return (
        <div>
            <div className="bg-white rounded-md shadow-md">
                <div className="w-full flex justify-between place-content-center">
                    <h2 className="text-xl font-bold inter500 p-6">
                        {TableName}
                    </h2>
                    <div className="flex justify-between place-items-center gap-2 mx-2">
                        <input
                            type="text"
                            placeholder="Search...."
                            className="border h-10 px-2 rounded shadow"
                            onChange={handleSearch}
                        />
                        <div className="relative inline-flex items-center border rounded-md shadow bg-white">
                            <div className="px-3 py-2">
                                <CiFilter className="text-lg" />
                            </div>
                            <select className="appearance-none w-full px-3 py-2 bg-transparent border-none focus:ring-0 focus:outline-none" onClick={handleFilter}>
                                <option value="" disabled>
                                    Filter
                                </option>
                                <option value="">Filter</option>
                                <option value="ACTIVE">Active</option>
                                <option value="INACTIVE">Inactive</option>
                                <option value="BLOCK">Block</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full border-collapse">
                        <thead className="bg-[#F1F5F9]">
                            <tr className="">
                                {TableHeader?.map((heading, index) => (
                                    <td
                                        key={index}
                                        className="py-3 px-4 capitalize inter500 "
                                    >
                                        {heading}
                                    </td>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {TableData && TableData.length > 0 ? (
                                TableData?.map((learner, index) => (
                                    <tr key={index} className="hover:bg-gray-100">
                                        <td className="py-2 ps-6 border-b">
                                            {(pageNo - 1) * size + index + 1}
                                        </td>
                                        <td className="py-2 ps-5 border-b inter500 text-[#0F172A]">
                                            <div className="flex justify-start items-center gap-3">
                                                <img
                                                    src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
                                                    alt="Avatar"
                                                    className="h-10 w-10 rounded-full"
                                                />
                                                <p className="capitalize">{learner.fullName}</p>
                                            </div>
                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">
                                            {learner.email}
                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">
                                            0
                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">
                                            0
                                        </td>
                                        <td className="py-2 px-4 border-b ">
                                            <button
                                                className={`px-4 py-1 rounded-full ${learner.isBlocked
                                                    ? "bg-[#FF3B3033]/20 text-[#FF3B30]"
                                                    : learner.isActive
                                                        ? "bg-[#CDF3E5] text-[#43A581]"
                                                        : "bg-[#FFF0D4] text-[#FBB000]"
                                                    }`}
                                            >
                                                {learner.isBlocked
                                                    ? "Blocked"
                                                    : learner.isActive
                                                        ? "Active"
                                                        : "Inactive"}
                                            </button>
                                        </td>
                                        <td className="py-2 px-4 border-b ">
                                            {learner.createdAt
                                                ? moment(learner?.createdAt).format(
                                                    "DD.MM.YYYY - hh.mm A"
                                                )
                                                : "-"}
                                        </td>
                                        <td className="py-2 px-4 border-b relative">
                                            <span className="w-8 h-8 rounded-md flex justify-center items-center border" onClick={() => showMenu(learner?.id)}>

                                                <BsThreeDotsVertical />
                                            </span>
                                            <div className="absolute top-10 right-32">

                                                {isMenu && clickedUserId === learner?.id && (
                                                    <ul className="bg-white w-36  rounded-md border shadow space-y-2">
                                                        <li className="px-3 pb-1 border-b p-2" onClick={()=>handleEdit(learner)}>Edit</li>
                                                        <li className="px-3 pb-1" onClick={()=>handleUserBlock(learner)}>Block</li>
                                                    </ul>
                                                )}

                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="py-4 text-center text-[#475569]">
                                        No data found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>



            </div>
        </div>
    );
};

export default LearningMangmentTable;















































