import React, { useState } from 'react'
import SessionMangmentTable from './Table/SessionMangmentTable'
import ViewModal from './Modal/ViewModal'

const SessionMangment = () => {
    const [isSessionModal, setIsSessionModal] = useState(false)
    const [isSessionModalData, setIsSessionModalData] = useState(null)

    const TbaleHeader = ['#', 'Session Title', 'Expert Name', ' Enrollment', 'Duration', 'Created  Date & Time', 'Action']
    const TbaleData = [{ id: 1, sessionName: "Exploring Advanced SEO Techniques", image: 'https://www.orfonline.org/public/uploads/seo/20240711144535.jpg', expertName: "Emma", Enrolled: "20 Enrolled", duration: "1 Hour", createdAt: 5671264 }]
    const viewAction = (data) => {
        console.log(data)
        setIsSessionModal(!isSessionModal); setIsSessionModalData(data)
    }
    const toggleSessionModal = () => {
        setIsSessionModal(!isSessionModal);
    }
    const handleSubmit = () => { }

    return (
        <div>
            <section>
                <div className="bg-gradient-to-r from-[#855DF6] to-[#715EFE]  h-[150px] shadow-lg  relative">
                    <h2 className="text-2xl font-bold  text-white dahboardFont p-10 px-12">Session Management</h2>
                    <div className="  gap-4 absolute w-full mx-auto p-14 top-11 right-1">
                        <SessionMangmentTable TbaleName={`Overall Session (100)`} TbaleHeader={TbaleHeader} TbaleData={TbaleData}
                            viewAction={viewAction} />
                    </div>
                </div>
            </section>

            {
                isSessionModal && (
                    <ViewModal onCancel={toggleSessionModal} onSubmit={handleSubmit} inputValue={isSessionModalData} />
                )
            }
        </div>
    )
}

export default SessionMangment