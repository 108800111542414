import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../_helpers/apiConfig";
import SuccessModal from "../components/ui/SuccessModal";

export const getChatslist = createAsyncThunk("/support/chattedUsers", async (filters) => {
  const response = await apiRequest('POST', 'v1/support/chattedUsers', filters);
  return response;
});


export const getmessageBetweenTwouser = createAsyncThunk("/support/getmessageBetweenTwouser",
  async (filters) => {
    const response = await apiRequest("POST", "v1/support/getmessageBetweenTwouser", filters);
    return response;
  }
);

export const getTicketList = createAsyncThunk("/user/ticketList",
  async (filters) => {
    const response = await apiRequest("POST", "v1/user/ticketList", filters);
    return response;
  }
);


export const updateTicketStatus = createAsyncThunk("/user/updateStatusofTicket",
  async (arg, thunkAPI) => {
    const { filters, forReferesh } = arg;
    console.log('filters,thunkAPI,forRefereshfilters,thunkAPI,forReferesh',filters,thunkAPI,forReferesh)
    const response = await apiRequest("POST", "v1/user/updateStatusofTicket", filters);
    if (response.message === "SUCCESS") {
      thunkAPI.dispatch(getTicketList(forReferesh));
      <SuccessModal isOpen={true}/>
    }
    return response;
  }
);


const helpAndSupportSlice = createSlice({
  name: 'helpAndSupport',
  initialState: {
    cardCounts: {},
    latestSignUp: [],
    total: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder


      .addCase(getTicketList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getTicketList.fulfilled, (state, action) => {
        state.loading = false;
        state.ticketList = action?.payload?.data;
      })
      .addCase(getTicketList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(updateTicketStatus.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(updateTicketStatus.fulfilled, (state, action) => {
        state.loading = false;
        state.updateStatus = action?.payload;
       
      })
      .addCase(updateTicketStatus.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


      .addCase(getmessageBetweenTwouser.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getmessageBetweenTwouser.fulfilled, (state, action) => {
        state.loading = false;
        state.chatsUser = action.payload.data;
      })
      .addCase(getmessageBetweenTwouser.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })




      .addCase(getChatslist.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(getChatslist.fulfilled, (state, action) => {
        state.loading = false;
        state.cardCounts = action.payload.data;
      })
      .addCase(getChatslist.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })


  },
});

export default helpAndSupportSlice.reducer


