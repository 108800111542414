import React from "react";
import { useLocation, useNavigate } from "react-router-dom";
import telgrosadmin from "../../public/images/telgorsAdminWhite.svg"
import { RxDashboard } from "react-icons/rx";
import { LuUsers } from "react-icons/lu";
import { RiUserStarLine } from "react-icons/ri";
import { TbMessage2Cog, TbTextScan2 } from "react-icons/tb";
import { MdOutlineAdminPanelSettings, MdOutlineNotificationsActive, MdOutlineSettingsApplications } from "react-icons/md";
import { TbCreditCardPay } from "react-icons/tb";
import { BiSupport } from "react-icons/bi";
import { PiCertificateLight, PiHandTap, PiVideoCameraLight } from "react-icons/pi";
import { VscGroupByRefType } from "react-icons/vsc";












function Sidebar({ SidebarJSON, navbarOpen, setNavbarOpen }) {
  const location = useLocation();
  const navigate = useNavigate();

  // const [showSection, setShowSection] = React.useState("");

  // const onClickMenu = (url) => {
  //     history.push(url);
  //     setNavbarOpen(!navbarOpen)
  //   }

  const onClickMenu = (url) => {
    navigate(url);
    setTimeout(() => {
      // setNavbarOpen(!navbarOpen);
    }, 150);
  };

  // const handleNavbar = () => {
  //   setNavbarOpen(!navbarOpen);
  // };

  const sidebarData = [
    {
      path: "/app/dashboard",
      icon: <RxDashboard size={16} />,
      sidebarName: "Dashboard Analytic",
    },
    {
      path: "/app/users",
      icon: <LuUsers size={16} />,
      sidebarName: "User Management",
    },
    {
      path: "/app/content",
      icon: <TbTextScan2 size={16} />,
      sidebarName: "Content Moderation",
    },
    {
      path: "/app/session",
      icon: <PiVideoCameraLight />,
      sidebarName: "Session Management ",
    },
    {
      path: "/app/community",
      icon: <TbMessage2Cog />,
      sidebarName: "Community Management",
    }, {
      path: "/app/payments&transactions",
      icon: <TbCreditCardPay size={16} />,
      sidebarName: "Payments & Transactions",
    }, {
      path: "/app/adsAndPromotation",
      icon: <RiUserStarLine size={16} />,
      sidebarName: "Ads Promotion Management",
    }, {
      path: "/app/certificate",
      icon: <PiCertificateLight size={16} />,
      sidebarName: "Certification Management ",
    }, {
      path: "/app/referal/reward",
      icon: <VscGroupByRefType size={16} />,
      sidebarName: "Referral & Rewards System",
    },
     {
      path: "/app/helpsupport",
      icon: <BiSupport size={16} />,
      sidebarName: "Support Ticket System  ",
    }, 
    {
      path: "/app/notifications",
      icon: <MdOutlineNotificationsActive size={16} />,
      sidebarName: "Notifications",
    },
    {
      path: "/app/preferencepanagement",
      icon: <PiHandTap size={16} />,
      sidebarName: "Preference Management",
    },
    {
      path: "/app/admin",
      icon: <MdOutlineAdminPanelSettings size={16} />,
      sidebarName: "Admin Management",
    },
    {
      path: "/app/appSetting",
      icon: <MdOutlineSettingsApplications size={16} />,
      sidebarName: "App Setting",
    },
  ];
 

  return (
    <>
      <button
        className={` ${navbarOpen === true ? "hidden" : "flex"
          } absolute top-0 left-0 px-5 items-center justify-center   z-50 h-16`}
        onClick={() => setNavbarOpen(!navbarOpen)}
      >
        {/* <svg className="h-6 w-6" stroke="currentColor" fill="none" viewBox="0 0 24 24"><path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h7"  ></path>
        </svg> */}
      </button>


      <div
        className={`${navbarOpen === false ? "hidden " : "flex "
          } fixed left-0 inset-y-0 w-64 z-50 flex-col bg-[#212B36]`}
      >
        <div className="flex flex-col w-64 relative flex-1 h-full max-h-full">

          <div className="cursor-pointer relative flex justify-between place-items-center flex-shrink-0 w-full z-50">
            <img src={telgrosadmin} alt="" className="h-[39px] mx-auto w-[185px] items-center md:ml-6 text-white m-4" onClick={()=> setNavbarOpen(!navbarOpen)}/>
            <div className="">
              {/* <MdOutlineMenu onClick={handleNavbar} className="text-white text-xl block mx-4" /> */}
            </div>
          </div>

          <nav className="overflow-y-auto  flex flex-col flex-wrap items-center justify-between relative w-64 z-10 h-full overflow-auto ">
            <div className="px-0 flex flex-col flex-wrap  pt-2 items-center w-full mx-auto flex-1 bg-[#212B36] ">
              <div className={"relative z-40 flex-1 w-full space-y-1"}>
                <div className="sidebarFont" >

                  {sidebarData.map((item, index) => (
                    <span
                      key={index}
                      onClick={() => onClickMenu(item.path)}
                      className={`cursor-pointer h-9 items-center flex space-x-4 px-4 py-1 text-sm transition ease-in-out duration-150 my-2 ${location.pathname === item.path
                        ? "text-white bg-[#FFFFFF1A]/10"
                        : "text-[#919EAB]"
                        }`}
                    >
                      <span className="group">{item.icon}</span>
                      <span className="text-base whitespace-nowrap sidebarFont">
                        {item.sidebarName}
                      </span>
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </nav>
        </div >
      </div >
    </>
  );
}

export default Sidebar;
