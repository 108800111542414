import React, { useState } from 'react'
import Card from '../../components/Card/Card'
import card1icon from "../../public/images/card1.svg"
import card2icon from "../../public/images/card2.svg"
import card3icon from "../../public/images/card3.svg"
import card4icon from "../../public/images/card4.svg"
import Button from '../../components/Button/Button'
import AddsTable from './Tables/AddsTable'
import PromotationTable from './Tables/PromotationTable'
import MarketingTable from './Tables/MarketingTable'
import CreateAdsModal from './Modal/CreateAdsModal'
import EditAdsModal from './Modal/EditAdsModal'
import CreatePromoModal from './Modal/CreatePromoModal '
import EditPromoModal from './Modal/EditPromoModal'
import CreateMarketingModal from './Modal/CreateMarketingModal'
import EditMarketingModal from './Modal/EditMarketingModal'
const adsFeilds = { pName: "", audience: "", period: "", charges: "" }
const PromoFeilds = { code: "", type: "", usageLimit: "", charges: "" }
const marketingFeilds = { name: "", startDate: "", endDate: "", reach: "", charges: "", campaignContent: "" }


const AdsAndPromotation = () => {
    const [activeTab, setActiveTab] = useState('AdsPromotionManagement');
    const [isCreateAddModal, setIsCreateModal] = useState(false)
    const [isEditAdsmodal, setIsEditAdsModal] = useState(false)
    const [isCreatePromoModal, setIsCreatePromoModal] = useState(false)
    const [isEditPromoModal, setIsPromoEditModal] = useState(false)
    const [isCreateMarketingModal, setIsCreatemarketingModal] = useState(false)
    const [promoEditModalData, setPromoEditModalData] = useState(null)
    const [inputsData, setInputsData] = useState(adsFeilds)
    const [promoInputsData, setPromoInputsData] = useState(PromoFeilds)
    const [marketingInputsData, setMarketingInputsData] = useState(marketingFeilds)
    const [editAdsData, setEditAdsData] = useState(null)
    const [isEditMarketingModal, setIsEditMarketingModal] = useState(false)
    const [marektingModalData, setMarektingModalData] = useState(null)
    const adsTableHeader = ['#', 'Promotation Name', 'Target Audience', 'Status', 'Active Period', 'Charges', 'Action']
    const promationTableHeader = ['#', 'Promotion Code', 'Discount Type', 'Status', 'Usage Limit', 'Charges', 'Action']
    const marketingTableHeader = ['#', 'Campaign Name', 'Date', 'Status', 'Reach', 'Budget', 'Action']

    const adsTableData = [
        {
            id: 1,
            name: "Blackfriday Off",
            audience: "Experts",
            status: "Active",
            period: "7 Days",
            charges: "$20",
        },
    ]
    const promotionTableData = [
        {
            id: 1,
            code: "summer2004",
            type: "20% off",
            status: "Active",
            usageLimit: "7 ",
            charges: "$20",
        },
    ]
    const marketingTableData = [
        {
            id: 1,
            name: "Teachers Day Promo",
            date: "01 Sep to 10 Sep",
            status: "Active",
            reach: "700 views ",
            charges: "$20",
        },
    ]
    const handleAdsView = () => { }
    const handleCreateAds = () => { setIsCreateModal(!isCreateAddModal) }
    const toggleAdsCreateModal = () => { setIsCreateModal(!isCreateAddModal) }
    const handleAdsCreateOnChnage = (e) => { const { name, value } = e.target; setInputsData({ ...inputsData, [name]: value }) }
    const submitCreateAds = () => { }

    const handleEditAds = (data) => { setIsEditAdsModal(!isEditAdsmodal); setEditAdsData(data) }
    const toggleEditAdsData = () => { setIsEditAdsModal(!isEditAdsmodal) }
    const handleAdsEditOnChange = (e) => { const { name, value } = e.target; setEditAdsData({ ...editAdsData, [name]: value }) }

    const handleCreatePromo = () => { setIsCreatePromoModal(!isCreatePromoModal) }
    const handleCreatePromoCreateOnChange = (e) => { const { name, value } = e.target; setPromoInputsData({ ...promoInputsData, [name]: value }) }
    const togglePromoCreateModal = () => { setIsCreatePromoModal(!isCreatePromoModal) }

    const handlePromoView = () => { }
    const handleEditPromo = (data) => { setIsPromoEditModal(!isEditPromoModal); setPromoEditModalData(data); }
    const toggleEditPromoModal = () => { setIsPromoEditModal(!isEditPromoModal) }
    const handleEditPromoOnChange = (e) => { const { name, value } = e.target; setPromoEditModalData({ ...promoEditModalData, [name]: value }) }

    const handleCreateMarketing = () => { setIsCreatemarketingModal(!isCreateMarketingModal) }
    const toggleCreateMarketing = () => { setIsCreatemarketingModal(!isCreateMarketingModal) }
    const handleCreateMarketingOnChnage = (e) => { const { name, value } = e.target; setMarketingInputsData({ ...marketingInputsData, [name]: value }) }
    const handleCreateMarketingSubmit = (e) => { e.preventDefault(); let apiPayload = { name: marketingInputsData?.name }; console.log(apiPayload) }
    const handleEditMarketing = (data) => { setIsEditMarketingModal(!isEditMarketingModal); setMarektingModalData(data);console.log(data) }
    const toggleEditMarketingModal=()=>{setIsEditMarketingModal(!isEditMarketingModal)}
    const handleEditMrketingOnChange=(e)=>{const {name,value}=e.target;setMarektingModalData({...marektingModalData,[name]:value})}

    return (
        <div>
            <div className="bg-gradient-to-r from-[#855DF6] to-[#715EFE] h-40  shadow-lg  relative">
                <h2 className="text-2xl font-bold  text-white dahboardFont px-7 pt-5">Advertising & Promotion Management</h2>
                <div className="grid lg:grid-cols-4 grid-cols-1 md:grid-cols-4  gap-4 absolute w-full mx-auto p-8 top-11 right-1">
                    <Card title={'Ads & Promotions '} content={`0`} img={card1icon} number={`` || 0} editableCompleted={`Total Ads`} />
                    <Card title={'Promotion Code '} content={``} img={card2icon} number={`` || 0} editableCompleted={`Total created Genrated`} />
                    <Card title={'Campaigns'} content={``} img={card3icon} number={`` || 0} editableCompleted={`Total Campaigns`} />
                    <Card title={'Campaigns Response '} content={``} img={card4icon} number={`$69`} editableCompleted={`Views`} />
                </div>
            </div>
            <section className='p-8'>
                <div className="flex relative space-x-3 h-10 w-full rounded-t-md border border-t-0 border-x-0 border-b-2 cursor-pointer mt-24">
                    <Button
                        label="Ads Promotion Management"
                        isActive={activeTab === "AdsPromotionManagement"}
                        onClick={() => setActiveTab("AdsPromotionManagement")}
                    />
                    <Button
                        label="Promotional Codes & Discounts "
                        isActive={activeTab === "PromotionalCodesDiscounts"}
                        onClick={() => setActiveTab("PromotionalCodesDiscounts")}
                    />
                    <Button
                        label="Marketing Campaigns "
                        isActive={activeTab === "MarketingCampaigns"}
                        onClick={() => setActiveTab("MarketingCampaigns")}
                    />
                </div>
                <div className='mt-5'>
                    {
                        activeTab === 'AdsPromotionManagement' && (
                            <AddsTable TableName={`Platform-Wide Advertisements and Promotions`} TableHeader={adsTableHeader} TableData={adsTableData}
                                handleCreateAds={handleCreateAds} editAction={handleEditAds} viewAction={handleAdsView} />
                        )
                    }
                    {
                        activeTab === 'PromotionalCodesDiscounts' && (
                            <PromotationTable TableName="Manage Promotional Codes and Discounts" TableHeader={promationTableHeader} TableData={promotionTableData}
                                handleCreateAds={handleCreatePromo} editAction={handleEditPromo} viewAction={handlePromoView} />
                        )
                    }
                    {
                        activeTab === 'MarketingCampaigns' && (
                            <MarketingTable TableName="Marketing Campaigns" TableHeader={marketingTableHeader} TableData={marketingTableData}
                                handleCreateMarketing={handleCreateMarketing} editAction={handleEditMarketing} />
                        )
                    }
                </div>
            </section>

            {
                isCreateAddModal && (<CreateAdsModal inputData={inputsData} handleONChange={handleAdsCreateOnChnage} onCancel={toggleAdsCreateModal} onSubmit={submitCreateAds} />)
            }
            {
                isEditAdsmodal && (<EditAdsModal editAdsData={editAdsData} onCancel={toggleEditAdsData} handleONChange={handleAdsEditOnChange} />)
            }
            {
                isCreatePromoModal && (<CreatePromoModal inputData={promoInputsData} handleONChange={handleCreatePromoCreateOnChange} onCancel={togglePromoCreateModal} />)
            }
            {
                isEditPromoModal && (<EditPromoModal onCancel={toggleEditPromoModal} editPromoData={promoEditModalData} handleONChange={handleEditPromoOnChange} />)
            }
            {
                isCreateMarketingModal && (<CreateMarketingModal onCancel={toggleCreateMarketing} inputData={marketingInputsData} title={` Create New Campaign`}
                    handleONChange={handleCreateMarketingOnChnage} onSubmit={handleCreateMarketingSubmit} />)
            }
            {
             isEditMarketingModal&&(<EditMarketingModal onCancel={toggleEditMarketingModal} handleONChange={handleEditMrketingOnChange} editPromoData={marektingModalData}/>)   
            }
        </div>
    )
}

export default AdsAndPromotation