import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { createAppSettings, getAppSettingList, updateAppSettings, updateAppSettingsStatus } from '../../Redux/appSettingSlice'
import { IoAddOutline } from 'react-icons/io5';
import AppSettingTable from './Table/AppSettingTable';
import CreateAppSettingModal from './Modal/CreateAppSettingModal';
import Loader from '../../components/Loader/Loader';


const feildsData = {
  name: "", key: "", value: ''
}

const AppSetting = () => {
  const selector = useSelector(state => state)
  const dispatch = useDispatch()
  const { appSettingSlice: { getAllAppSettings: { total = [], list = null }, loading } } = selector ? selector : {}
  const [clickedUserId, setClickedUserId] = useState(null)
  const [isMenu, setIsMenu] = useState(false)
  const [error, setError] = useState({})
  const tableHead = ['#', 'Id', 'name', 'value', 'Status', 'CreatedAt', 'Action']
  /// Row Data====>>>>
  const [clickedRowData, setClickedRowData] = useState(null)
  
  /// Create State ===>>>>
  const [isCreateAppSettingModal, setIsCreateAppSettingModal] = useState(false)
  const [inputValue, setIsInputValue] = useState(feildsData)

  /// Edit State=====>
  const [isEditModal, setIsEditModal] = useState(false)


  const apiFetchCred = {
    "pageNo": '1',
    "size": 5,
    "keyWord": ""
  }
  useEffect(() => {
    dispatch(getAppSettingList(apiFetchCred))
  }, [dispatch])

  const showMenu = (chatUserId) => {
    if (clickedUserId === chatUserId) {
      setIsMenu(!isMenu);
    } else {
      setClickedUserId(chatUserId);
      setIsMenu(true);
    }
  };

  const handleCreateValidate = () => {
    let newError = {}
    if (!inputValue?.name?.trim()) newError.name = 'Name is required';
    if (!inputValue?.key?.trim()) newError.key = 'Key is required';
    if (!inputValue?.value?.trim()) newError.value = 'Password is required';
    setError(newError);
    return Object.keys(newError).length === 0
  }
  const handleEditValidate = () => {
    let newError = {}
    if (!clickedRowData?.name?.trim()) newError.name = 'Name is required';
    if (!clickedRowData?.key?.trim()) newError.key = 'Key is required';
    if (!clickedRowData?.value?.trim()) newError.value = 'Password is required';
    setError(newError);
    return Object.keys(newError).length === 0
  }

  const handleCreateAppSettings = () => { setIsCreateAppSettingModal(!isCreateAppSettingModal) }
  const toggleCreateModal = () => { setIsCreateAppSettingModal(!isCreateAppSettingModal); setIsInputValue(null); setError(null) }
  const handleCreateOnChange = (e) => { const { name, value } = e.target; setIsInputValue({ ...inputValue, [name]: value }); setError(null) }

  const handleCreateSubmit = (e) => {
    e.preventDefault()
    if (!handleCreateValidate()) return;
    let payload = {
      name: inputValue?.name,
      key: inputValue?.key,
      value: inputValue?.value
    }
    dispatch(createAppSettings({ Payload: payload, forReferesh: apiFetchCred }))
    setIsCreateAppSettingModal(false)
    setIsInputValue(null)
    setError(null)

  }

  const handleAction = async (action, data) => {
    setClickedRowData(data)
    switch (action) {
      case 'EDIT':
        setIsEditModal(prevState => !prevState)
        break;

      case 'STATUS':
        let payload = {
          "id": data?._id
        }
        dispatch(updateAppSettingsStatus({ Payload: payload, forReferesh: apiFetchCred }))

        break;
      default:
        break;
    }
  }

  const toggleupdateModal = () => { setIsEditModal(prevState => !prevState);setError(null) }
  const handleEditOnChange = (e) => { const { name, value } = e.target; setClickedRowData({ ...clickedRowData, [name]: value }); setError(null)  }
  const handleUpdateAppSettings = (e) => {
    e.preventDefault();
    if (!handleEditValidate()) return;
    let payload = {
      id: clickedRowData?._id,
      name: clickedRowData?.name,
      key: clickedRowData?.key,
      value: clickedRowData?.value
    }
    dispatch(updateAppSettings({ Payload: payload, forReferesh: apiFetchCred }))
    setIsEditModal(prevState => !prevState)
    setClickedRowData(null)
  }
  return (
    <div className=''>
      <Loader loading={loading} />
      <section className=' h-52 lg:pt-16 pt-10 bg-gradient-to-r from-[#855DF6] to-[#715EFE] '>
        <div className='2xl:w-11/12 w-10/12 lg:w-11/12 mx-auto'>
          <div className='flex justify-between place-items-center'>
            <h2 className='font-bold md:text-3xl text-xl text-[#fff]'>App Setting Management</h2>
            <button className='bg-white rounded-md text-center p-2 transition-colors duration-300 flex justify-center place-items-center gap-2' onClick={handleCreateAppSettings}>
              <span><IoAddOutline /></span>
              <span>Create App Settings</span>
            </button>
          </div>
          {/* ============>>>>> App seting table <<<<<===========*/}
          <div className='grid lg:grid-cols-1 md:gap-10 bg-[#FFFFFF] rounded-lg p-8 translate-y-12'>
            <AppSettingTable tableName={`App Settings`} thead={tableHead} data={list} total={total} showMenu={showMenu} clickedUserId={clickedUserId} isMenu={isMenu}
              onActionClick={handleAction} />
          </div>
        </div>
      </section>

      {/*========>>>>>> create Modal <<<<<<===================*/}
      {
        isCreateAppSettingModal && (<CreateAppSettingModal title={`Create App Setting`} onCancel={toggleCreateModal} inputData={inputValue} handleONChange={handleCreateOnChange} onSubmit={handleCreateSubmit} error={error} />)
      }

      {/*========>>>>>> Update Modal <<<<<<===================*/}
      {
        isEditModal && (<CreateAppSettingModal title={`Update App Settings`} inputData={clickedRowData} onCancel={toggleupdateModal} onSubmit={handleUpdateAppSettings} handleONChange={handleEditOnChange} error={error} />)
      }
    </div>
  )
}

export default AppSetting