import React, { useEffect, useRef } from 'react'

import { RiDeleteBin6Line } from 'react-icons/ri';
import { LiaEdit } from 'react-icons/lia';
import { CiFilter } from 'react-icons/ci';


const RewardTable = ({ TbaleName, TbaleHeader, TbaleData, showMenu, viewAction, handleToggle, isMenu, clickedUserId, handleSearch, handleFilter, handleCreateModal }) => {
    const ContentActionRef = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ContentActionRef.current && !ContentActionRef.current.contains(event.target)) {
                showMenu(null);
            }
        };


        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);
    return (
        <div>
            <div>
                <div className=" bg-white rounded-md shadow-md">
                    <div className='w-full flex justify-between place-content-center'>

                        <h2 className="text-xl font-bold inter500 p-[18px]">{TbaleName}</h2>
                        <div className="flex justify-between place-items-center gap-2 mx-2">
                            <input
                                type="text"
                                placeholder="Search...."
                                className="border h-10 px-2 rounded shadow"
                                onChange={handleSearch}
                            />
                            <div className="relative inline-flex items-center border rounded-md shadow bg-white">
                                <div className="px-3 py-2">
                                    <CiFilter className="text-lg" />
                                </div>
                                <select className="appearance-none w-full px-3 py-2 bg-transparent border-none focus:ring-0 focus:outline-none" onClick={handleFilter}>
                                    <option value="" disabled>
                                        Filter
                                    </option>
                                    <option value="">Filter</option>
                                    <option value="ACTIVE">Active</option>
                                    <option value="INACTIVE">Inactive</option>
                                    <option value="BLOCK">Block</option>
                                </select>

                            </div>
                            <button className="ml-2 border px-3 py-2 rounded-md bg-[#715EFE] text-white hover:bg-blue-600"onClick={()=>handleCreateModal('REWARD')}>
                                + Add New Reward
                            </button>
                        </div>
                    </div>
                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse">
                            <thead className='bg-[#F1F5F9] '>
                                <tr>
                                    {
                                        TbaleHeader.map((heading) => (
                                            <>
                                                <td className='py-3 px-4 capitalize inter500 '>{heading}</td>
                                            </>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {TbaleData?.map((ele) => (
                                    <tr key={ele.id} className="hover:bg-gray-100">
                                        <td className="py-2 ps-6 border-b">{ele.id}</td>
                                        <td className="py-2 ps-5 border-b inter500 text-[#0F172A]">
                                            <div className=' gap-3 flex justify-start items-center'>

                                                {ele.name}
                                            </div>

                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">{ele.type}</td>
                                        <td className="py-2 px-4 border-b ">
                                            <label className="inline-flex items-center cursor-pointer">
                                                <input
                                                    type="checkbox"
                                                    className="sr-only peer"
                                                    checked={ele?.status}
                                                    onChange={() => handleToggle(ele)}
                                                />
                                                <div
                                                    className={`relative w-11 h-6 rounded-full transition-all ${ele?.status ? "bg-[#d9d9df]" : "bg-[#715EFE33]/30"
                                                        }`}
                                                >
                                                    {/* Toggle Dot */}
                                                    <div
                                                        className={`absolute top-0.5 start-[2px] h-5 w-5 rounded-full border border-gray-300 transition-transform ${ele?.status
                                                            ? "translate-x-full bg-[#656565]"
                                                            : "bg-[#715EFE] translate-x-0"
                                                            }`}
                                                    ></div>
                                                </div>

                                            </label>
                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">{ele?.reward}</td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">{ele.Conditions}</td>

                                        <td className="py-2 px-4 border-b ">
                                            <div className='flex justify-start gap-3 items-center relative'>
                                                <button className='bg-[#E5E5EA] p-2 rounded flex justify-center ' onClick={() => viewAction(ele)}>
                                                    <RiDeleteBin6Line className='text-lg' />
                                                </button>
                                                <button className='bg-[#E5E5EA] p-2 rounded flex justify-center ' onClick={() => viewAction(ele)}>
                                                    <LiaEdit className='text-lg' />
                                                </button>

                                            </div>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div >
            </div>
        </div>
    )
}

export default RewardTable






