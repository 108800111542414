import React from 'react'
import InputField from '../../../components/ui/Input'
import Select from 'react-select';



const CreateModal = ({ onCancel, onSubmit, title, inputData, handleONChange, multiSelectOptions, handleMultiSelectChange, error }) => {
    return (
        <div>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg shadow-lg w-[50rem]">
                    <div className='border-b w-full'>
                        <div className="flex justify-between items-center border-b pb-2 p-6">
                            <h3 className="text-lg font-semibold">{title}</h3>
                            <button
                                className="text-gray-500 text-2xl w-10 h-10 bg-gray-100 rounded-full  hover:text-gray-800"
                                onClick={onCancel}
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                    <div className=' p-4'>
                        <div className='grid grid-cols-2 gap-4 items-center'>
                            <div>
                                <InputField label={`Name`} name='name' value={inputData?.name} onChange={handleONChange} />
                                {error?.name && <p className="text-red-500 text-sm">{error?.name}</p>}
                            </div>
                            <div>
                                <InputField label={`Role Type`} name='roleType' value={inputData?.roleType} onChange={handleONChange} />
                                {error?.roleType && <p className="text-red-500 text-sm">{error?.roleType}</p>}
                            </div>
                        </div>
                        <InputField label={`Email Id`} name='email' value={inputData?.email} onChange={handleONChange} />
                        {error?.email && <p className="text-red-500 text-sm">{error?.email}</p>}
                        <InputField label={`Password`} name='password' value={inputData?.password} onChange={handleONChange} />
                        {error?.password && <p className="text-red-500 text-sm">{error?.password}</p>}

                        <div>
                            <label className='block text-sm font-medium text-gray-700 mb-1'>Select Access</label>
                            <Select
                                isMulti
                                options={multiSelectOptions}
                                value={inputData?.permissions || []}
                                onChange={handleMultiSelectChange}
                                className="basic-multi-select"
                                classNamePrefix="select"
                            />
                            {error?.permissions && <p className="text-red-500 text-sm">{error?.permissions}</p>}

                        </div>

                    </div>


                    <div className="flex justify-end space-x-3 mt-6 border-t p-4">
                        <button
                            className="px-6 py-2 border border-[#B8B8D2] text-gray-700 rounded hover:bg-gray-300"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-6 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
                            onClick={onSubmit}
                        >
                            Create Admin Role
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateModal