import React from 'react'
import { IoCloseOutline } from 'react-icons/io5'
import InputField from '../../../components/ui/Input'

const RefundModal = ({ title, onCancel, inputValue, onSubmit ,handleRefundOnChange}) => {
  return (
    <div>
           <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                <div className="bg-white rounded-lg shadow-lg w-full max-w-xl overflow-hidden overflow-y-auto">
                    <div className='w-full flex justify-between items-center p-4'>
                        <p>{title}</p>
                        <button onClick={onCancel} className='w-8 h-8 rounded-full bg-[#F2F2F7] flex justify-center items-center'>
                            <IoCloseOutline />

                        </button>
                    </div>

                    <div className="p-2 border-t border-gray-300">
                     
                    </div>

                    <div className="p-4 grid grid-cols-1 md:grid-cols-1 gap-4">
                        <div>
                            <InputField label={`Refund Amount`} placeholder={``} name='amount' value={inputValue?.amount} onChange={handleRefundOnChange}/>
                        </div>
                        <div>
                            <InputField label={`Sent to id`} placeholder={``} name='id' value={inputValue?.id} onChange={handleRefundOnChange}/>
                        </div>
                    </div>


                   

                    <div className="flex justify-end space-x-3  border-t p-4">
                        <button
                            className="px-6 py-2 border border-[#B8B8D2] text-gray-700 rounded hover:bg-gray-300"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-6 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
                            onClick={onSubmit}
                        >
                            Refund Now
                        </button>
                    </div>
                </div>
            </div>
    </div>
  )
}

export default RefundModal