import React from "react";
import { Route, Navigate, Routes } from 'react-router-dom';
import Layout from "./Layout/Layout";
import NetworkDetector from '../components/Hoc/NetworkDetector';
import Error from "../pages/error/Error";
import Login from "../pages/login/Login";
// import Dashboard from "../pages/dashboard/Dashboard";

const App = () => {





  
  return (
    <Routes>
      <Route path="/" element={<Navigate to="/login" />} />
      <Route path="/app/*" element={<PrivateRoute component={Layout} />} />
      <Route path="/login" element={<PublicRoute component={Login} />} />
      {/* <Route path="/dashboard" element={<PublicRoute component={Dashboard} />} /> */}

      <Route path="*" element={<Error />} />
    </Routes>
  );
};

const PrivateRoute = ({ component: Component, ...rest }) => {
  const isAuthenticated = localStorage.getItem('accessToken');
  return isAuthenticated ? <Component {...rest} /> : <Navigate to="/login" />;
};

const PublicRoute = ({ component: Component, ...rest }) => {
  return <Component {...rest} />;
};

export default NetworkDetector(App);
