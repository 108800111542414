import React, { Fragment, useEffect, useRef, useState } from "react";
// import { Menu, Transition } from '@headlessui/react';
import { logoutFunction } from "../../_helpers";
// import userLogo from "./user.png"
// import { FaRegCircleUser } from "react-icons/fa6";
// import { IoIosArrowDown } from "react-icons/io";
import { IoLogOutOutline } from "react-icons/io5";
import { MdOutlineMenu } from 'react-icons/md'
import { useDispatch,  } from "react-redux";
import profileIcon from "../../public/images/profile.svg"
import { IoIosNotificationsOutline } from "react-icons/io";

// import ChangePasswordModel from "../../pages/dashboard/ChangePasswordModel";
import { logout } from '../../Redux/userSlice';
// function classNames(...classes) {
//   return classes.filter(Boolean).join(' ');

  
// }

export default function Header({ HeaderJSON, handleNavbar }) {
  // const selector = useSelector(state => state)
  const [openmodel, setopenmodel] = useState(false);
  const dispatch = useDispatch();
  const dropDownRef = useRef(null)
  const handelLogout = () => {
    logoutFunction()
    dispatch(logout())
  }

  const handleLogoutModal = () => {
    setopenmodel(!openmodel)
  }
  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropDownRef.current && !dropDownRef.current.contains(event.target)) {
        setopenmodel(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);



  return (
    <Fragment>
      <div className="bg-white shadow  z-10 flex-shrink-0 flex h-14 border-b border-black border-opacity-10">
        <div className="flex-1 px-4 flex justify-between">

          <div className="flex justify-between items-center space-x-4">
            {/* <div><MdOutlineMenu className="text-xl" /></div> */}
            <div><MdOutlineMenu className="text-xl" onClick={handleNavbar} /></div>
          </div>
          <div className="flex items-center space-x-4">
            {/* <button onClick={handelLogout}><IoLogOutOutline /></button> */}
            <IoIosNotificationsOutline className="text-lg" />

            <img className="h-8 relative cursor-pointer" src={profileIcon} onClick={handleLogoutModal} alt="logo" />
            {
              openmodel && (
                <div className="absolute right-0 mt-16 w-48 bg-white shadow-lg rounded-md " ref={dropDownRef} >
                  <button
                    onClick={handelLogout}
                    className="block w-full text-left px-4 py-2 text-sm text-gray-700 hover:bg-gray-100 hover:text-red-600"
                  >
                    <IoLogOutOutline className="inline-block mr-2 " />
                    Logout
                  </button>
                </div>
              )
            }
          </div>
        </div>

      </div>
    </Fragment>
  );
}
