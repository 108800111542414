import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { apiRequest } from "../_helpers/apiConfig";


export const getAppSettingList = createAsyncThunk("/appSetting/getAppSettingList", async (filters) => {
    const response = await apiRequest('POST', 'v1/appSetting/getAppSettingList', filters);
    return response;
});



export const createAppSettings = createAsyncThunk("/appSetting/createAppSetting",
    async (arg, thunkAPI) => {
      const { Payload, forReferesh } = arg;
      console.log('filters,thunkAPI,forRefereshfilters,thunkAPI,forReferesh',Payload,thunkAPI,forReferesh)
      const response = await apiRequest("POST", "v1/appSetting/createAppSetting", Payload);
      if (response.message) {
        thunkAPI.dispatch(getAppSettingList(forReferesh));
      }
      return response;
    }
  );

  export const updateAppSettings = createAsyncThunk("/appSetting/updateAppSetting",
    async (arg, thunkAPI) => {
      const { Payload, forReferesh } = arg;
      console.log('filters,thunkAPI,forRefereshfilters,thunkAPI,forReferesh',Payload,thunkAPI,forReferesh)
      const response = await apiRequest("POST", "v1/appSetting/updateAppSetting", Payload);
      if (response.message) {
        thunkAPI.dispatch(getAppSettingList(forReferesh));
      }
      return response;
    }
  );


  export const updateAppSettingsStatus = createAsyncThunk("/appSetting/updateAppSettingStatus",
    async (arg, thunkAPI) => {
      const { Payload, forReferesh } = arg;
      console.log('filters,thunkAPI,forRefereshfilters,thunkAPI,forReferesh',Payload,thunkAPI,forReferesh)
      const response = await apiRequest("POST", "v1/appSetting/updateAppSettingStatus", Payload);
      if (response.message) {
        thunkAPI.dispatch(getAppSettingList(forReferesh));
      }
      return response;
    }
  );



const appSettingsSlice = createSlice({
    name: 'appSettings',
    initialState: {
        getAllAppSettings: {},
        latestSignUp: [],
        total: null,
        loading: false,
        error: null,
    },
    reducers: {},
    extraReducers: (builder) => {
        builder


            .addCase(getAppSettingList.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(getAppSettingList.fulfilled, (state, action) => {
                state.loading = false;
                state.getAllAppSettings = action?.payload?.data;
            })
            .addCase(getAppSettingList.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(createAppSettings.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(createAppSettings.fulfilled, (state, action) => {
                state.loading = false;
                state.cretaeAppSetting = action?.payload;
            })
            .addCase(createAppSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


            .addCase(updateAppSettings.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateAppSettings.fulfilled, (state, action) => {
                state.loading = false;
                state.updateAppSetting = action?.payload;
            })
            .addCase(updateAppSettings.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })


            .addCase(updateAppSettingsStatus.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(updateAppSettingsStatus.fulfilled, (state, action) => {
                state.loading = false;
                state.updateStatusAppSetting = action?.payload;
            })
            .addCase(updateAppSettingsStatus.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })










    },
});

export default appSettingsSlice.reducer