import React from 'react'
import moment from 'moment';
// import { FaRocketchat } from "react-icons/fa";

import { BsThreeDotsVertical } from 'react-icons/bs';
// import ReactPaginate from 'react-paginate';

const AppSettingTable = ({ thead, tableName, data, showMenu, isMenu, clickedUserId, onActionClick, size, total, handlePageClick, }) => {
    const getStatusClass = (status) => {
        switch (status) {
            case true:
                return 'bg-[#E3E6FF] text-[#6D7DFF]';
            case false:
                return 'bg-[#CDF3E5] text-[#43A581]';

            default:
                return '';
        }
    };

    // const onPageChange = () => { }


    return (
      
            <div className="w-auto overflow-hidden overflow-x-auto ">
                <div className="  bg-white w-full rounded-t-md border border-b">
                    <p className="p-3 text-xl">{tableName}</p>
                </div>
                <div className=''>

                    <table className=" w-full  bg-white border border-gray-200 border-t-0 table-auto overflow-hidden overflow-x-auto ">
                        <thead className='bg-[#F1F5F9]'>
                            <tr className='text-center'>
                                {thead?.map((col) => (
                                    <th
                                        key={col}
                                        className='py-2.5 px-3 capitalize inter500 '>
                                        {col}
                                    </th>
                                ))}
                            </tr>
                        </thead>
                        <tbody>
                            {data && data.length > 0 ? (
                                data?.map((ele, index) => (
                                    <tr key={index} className="text-[#0F172A]  border-b hover:bg-gray-50">
                                        <td className="py-4 px-3 ">{index + 1}</td>
                                        <td className="py-2 px-3 flex justify-start place-items-start gap-2">
                                            {ele?._id || '-'}
                                        </td>
                                        <td className="py-2 px-3 ">{ele?.name || '-'}</td>
                                        <td className="py-2 px-3  ">{ele?.value || '-'}</td>

                                        <td className="py-3 px-3 ">
                                            <span className={` px-4 py-2 rounded-full  ${getStatusClass(ele.isDisable)}`}>
                                                {!ele?.isDisable ? 'Active' : 'Inactive'}
                                            </span>
                                        </td>
                                        <td className="px-3 py-3">{ele.createdAt ? moment(ele.createdAt).format('MM-DD-YYYY, hh-mm A') : "-"}</td>
                                        <td className="py-2 px-3 text-center relative">
                                            <span
                                                className="w-8 h-8 rounded-md  flex justify-center items-center border border-gray-300 cursor-pointer hover:bg-gray-100 transition-colors"
                                                onClick={() => showMenu(ele?._id)}
                                            >
                                                <BsThreeDotsVertical />

                                            </span>

                                            <div className="absolute top-10 right-0 mt-2 w-40 bg-white z-50">
                                                {isMenu && clickedUserId === ele?._id && (
                                                    <ul className="bg-[#FFFFFF] rounded-md  shadow space-y-2 text-left ">
                                                        <li
                                                            className="px-4 py-1 border-b  text-gray-700 hover:bg-[#725EFE33] hover:text-[#715EFE] cursor-pointer transition-colors"
                                                            onClick={() => onActionClick('EDIT', ele)}
                                                        >
                                                            Edit
                                                        </li>


                                                        <li
                                                            className="px-4 py-1 border-b text-gray-700 hover:bg-[#725EFE33] hover:text-[#715EFE] cursor-pointer transition-colors"
                                                            onClick={() => onActionClick('STATUS', ele)}
                                                        >
                                                            Update Status
                                                        </li>


                                                    </ul>
                                                )}
                                            </div>
                                        </td>


                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="7" className="py-4 text-center text-gray-500">No  Data Available</td>
                                </tr>
                            )}
                        </tbody>

                    </table>
                </div>
                <div className="w-full bg-white rounded-b-md border border-b py-2">

                </div>
            </div>
        
    )
}

export default AppSettingTable