import React from 'react'



const Card = ({ title, img, content, number,editableCompleted }) => {
    return (
        <div>
            <div className="bg-white rounded-lg md:p-4 p-1 shadow-md">
                <div className="mt-0 md:mt-2 flex justify-between items-center">
                    <h3 className="text-sm font-bold mb-2 md:text-base lg:text-lg inter500">{title}</h3>
                    <span className="rounded-full px-2 py-1 text-xs font-bold md:text-sm lg:text-base">
                        <img src={img} className="h-6 w-6 md:h-8 md:w-8 lg:h-10 lg:w-10" alt='img'/>
                    </span>
                </div>
                <p className="text-sm font-bold mb-2 md:text-3xl lg:text-4xl Dmsans700">{number}</p>
                <p className="text-xs inter400 text-[#637381] md:text-sm lg:text-base">
                    <span className="text-sm text-[#454F5B] pe-2 md:text-base lg:text-lg">{content}</span>
                    {editableCompleted?editableCompleted:'Completed'}
                </p>
            </div>
        </div>

    )
}

export default Card

