import React from "react";

const Button = ({ label, onClick, isActive, className = "" }) => {
    return (
        <button type="button"
            className={`rounded-t-md text-center text-[14px] md:text-base p-2 transition-colors duration-300 ${isActive ? 'bg-gradient-to-r from-[#855DF6] to-[#715EFE] text-white' : 'border-x-2 border-t-2 bg-white'
                } hover:bg-blue-600 hover:text-white ${className}`}
            onClick={onClick}
        >
            {label}
        </button>
    );
};

export default Button;
