import React, { useState } from 'react'
import PaymentTransTable from './Table/PaymentTransTable'
import RefundModal from './Modal/RefundModal'
import UserPaymentDetails from './Modal/UserPaymentDetails'

const PaymentsAndTransactions = () => {
    const [isUserPaymentModal, setIsUserPaymentModal] = useState(false)
    const [isUserPaymentModalData, setIsUserPaymentModalData] = useState(null)

    const [isMenu, setIsMenu] = useState(false)
    const [clickedUserId, setClickedUserId] = useState(null)
    const [isRefundModal, setIsRefundModal] = useState(false)
    const [isRefundModalData, setIsRefundModalData] = useState(null)


    const TbaleHeader = ['#', ' Transaction ID', 'Payment Type', 'User Name ', 'Date & Time', 'Status', 'Amount', 'Action']
    const TbaleData = [
        { id: 1, sessionName: "#1234", expertName: "Course", Enrolled: "Addition Smith ", status: 1, amount: 120, createdAt: 5671264 },
        { id: 2, sessionName: "#1234", expertName: "Course", Enrolled: "Addition Smith ", status: 1, amount: 120, createdAt: 5671264 },
        { id: 3, sessionName: "#1234", expertName: "Course", Enrolled: "Addition Smith ", status: 1, amount: 120, createdAt: 5671264 },
        { id: 4, sessionName: "#1234", expertName: "Course", Enrolled: "Addition Smith ", status: 1, amount: 120, createdAt: 5671264 },
        { id: 5, sessionName: "#1234", expertName: "Course", Enrolled: "Addition Smith ", status: 1, amount: 120, createdAt: 5671264 },
    ]
    const viewAction = (data) => {
        setIsUserPaymentModal(!isUserPaymentModal); setIsUserPaymentModalData(data)
    }
    const toggleUserPaymentModal = () => {
        setIsUserPaymentModal(!isUserPaymentModal);
    }
    const showMenu = (userId) => {
        if (clickedUserId === userId) {
            setIsMenu(!isMenu);
        } else {
            setClickedUserId(userId);
            setIsMenu(true);
        }
    };
    const handleRefundPayment = (data) => { setIsRefundModal(!isRefundModal); setIsRefundModalData(data) };
    const toggleRefundModal = () => { setIsRefundModal(!isRefundModal); }
    const handleRefundOnChange = (e) => { const { name, value } = e.target; setIsRefundModalData({ ...isRefundModalData, [name]: value }) }
    const handleSubmit = (e) => { e.preventDefault(); console.log({ id: isRefundModalData?.id, amount: isRefundModalData?.amount }) }
    const copyId = () => { }


    return (
        <div>
            <section>
                <div className="bg-gradient-to-r from-[#855DF6] to-[#715EFE]  h-[150px] shadow-lg  relative">
                    <h2 className="text-2xl font-bold  text-white dahboardFont p-10 px-12">Payments & Transactions</h2>
                    <div className="  gap-4 absolute w-full mx-auto p-14 top-11 right-1">
                        <PaymentTransTable TbaleName={`Transactions History`} TbaleHeader={TbaleHeader} TbaleData={TbaleData} copyId={copyId}
                            viewAction={viewAction} isMenu={isMenu} showMenu={showMenu} clickedUserId={clickedUserId} handleRefundPayment={handleRefundPayment}
                        />
                    </div>
                </div>
            </section>
            {
                isRefundModal && (
                    <RefundModal title={`Refund Payment`} isRefundModalData={isRefundModalData} onCancel={toggleRefundModal} inputValue={isRefundModalData} onSubmit={handleSubmit} handleRefundOnChange={handleRefundOnChange} />
                )
            }
            {
                isUserPaymentModal && (
                    <UserPaymentDetails onClose={toggleUserPaymentModal} transactions={isUserPaymentModalData}/>
                )
            }
        </div>
    )
}

export default PaymentsAndTransactions