import React from 'react'
import InputField from '../../../components/ui/Input'
import { IoCloseOutline } from "react-icons/io5";

const ContentViewModal = ({title,  onSubmit, onCancel, inputValue }) => {
    return (
        <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
            <div className="bg-white rounded-lg shadow-lg w-full max-w-7xl h-[95vh] overflow-hidden overflow-y-auto">
                <div className='w-full flex justify-between items-center p-4'>
                    <p>{title}</p>
                    <button onClick={onCancel} className='w-8 h-8 rounded-full bg-[#F2F2F7] flex justify-center items-center'>
                        <IoCloseOutline />

                    </button>
                </div>

                <div className="p-4 border-t border-gray-300">
                    <div className="p-4">
                        <img
                            src={inputValue?.image}
                            alt="Content"
                            className="rounded-lg w-full object-cover"
                        />
                    </div>
                </div>

                <div className="p-4 grid grid-cols-1 md:grid-cols-2 gap-4">
                    <div>
                        <InputField label={`Uploaded By`} placeholder={``} value={inputValue?.uploadBy}/>
                    </div>
                    <div>
                        <InputField label={`Content Type`} placeholder={``} value={inputValue?.type===1?'Image':'Video'}/>
                    </div>
                </div>


                <div className="p-4">
                    <InputField label={`Comments`} placeholder={`Write hese`} value={inputValue?.coments}/>
                </div>

                <div className="flex justify-end space-x-3 mt-6 border-t p-4">
                    <button
                        className="px-6 py-2 border border-[#B8B8D2] text-gray-700 rounded hover:bg-gray-300"
                        onClick={onCancel}
                    >
                        Cancel
                    </button>
                    <button
                        className="px-6 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
                        onClick={onSubmit}
                    >
                        Approve Content
                    </button>
                </div>
            </div>
        </div>
    )
}

export default ContentViewModal