import React, { useEffect, useRef } from 'react'
import { HiOutlineDotsVertical } from "react-icons/hi";
import ContentAction from './ContentAction';


const ContentTable = ({ TbaleName, TbaleHeader, TbaleData, showMenu, viewAction, removeContent, isMenu, clickedUserId }) => {
    const ContentActionRef = useRef(null)
    useEffect(() => {
        const handleClickOutside = (event) => {
            if (ContentActionRef.current && !ContentActionRef.current.contains(event.target)) {
                showMenu(null); 
            }
        };


        document.addEventListener('mousedown', handleClickOutside);

        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, [showMenu]);
    return (
        <div>
            <div>
                <div className=" bg-white rounded-md shadow-md">
                    <h2 className="text-xl font-bold inter500 p-[18px]">{TbaleName}</h2>
                    <div className="overflow-x-auto">
                        <table className="w-full border-collapse">
                            <thead className='bg-[#F1F5F9] '>
                                <tr>
                                    {
                                        TbaleHeader.map((heading) => (
                                            <>
                                                <td className='py-3 px-4 capitalize inter500 '>{heading}</td>
                                            </>
                                        ))
                                    }
                                </tr>
                            </thead>
                            <tbody>
                                {TbaleData.map((ele) => (
                                    <tr key={ele.id} className="hover:bg-gray-100">
                                        <td className="py-2 ps-6 border-b">{ele._id}</td>
                                        <td className="py-2 ps-5 border-b inter500 text-[#0F172A]">
                                            <div className=' gap-3'>

                                                {ele.contentId}
                                            </div>

                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">{ele.uploadBy}</td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">{ele.type === 1 ? 'Video' : 'Image'}</td>
                                        <td
                                            className='py-2 px-4 border-b '
                                        >
                                            <button className={`px-4 py-1 rounded-full ${ele?.isActive?'bg-green-500/10 text-green-600':"text-red-500 bg-red-500/10"}`}>
                                                {ele?.isActive===true?'Active':'Inactive'}
                                            </button>
                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">{ele.uploadDate}</td>


                                        <td className="py-2 px-4 border-b ">{ele.flacgingDate}</td>
                                        <td className="py-2 px-4 border-b ">
                                            <div className=' gap-2 relative'>
                                                <button className='bg-[#E5E5EA] p-2 rounded flex justify-center ' onClick={() => showMenu(ele?._id)}>
                                                    <HiOutlineDotsVertical className='text-lg' />
                                                </button>
                                                <div className="absolute top-10 right-10 z-[9999]" >
                                                    {isMenu && clickedUserId === ele?._id && (
                                                        <div ref={ContentActionRef}>
                                                            <ContentAction removerContent={() => removeContent(ele)} viewAction={() => viewAction(ele)} />

                                                        </div>
                                                    )}

                                                </div>
                                            </div>
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div >
            </div>
        </div>
    )
}

export default ContentTable