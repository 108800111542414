import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { adminLogin } from '../../Redux/userSlice';
import admin from '../../public/images/telgrosadmin.svg'
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader/Loader';
// import './login.css'

const Login = () => {
  const dispatch = useDispatch();
  const selector = useSelector((state) => state); // Access loading and error from the Redux state
  const [credentials, setCredentials] = useState({ email: '', password: '' });
  const navigate = useNavigate();
  const [isChecked, setIsChecked] = useState(false);

  const { authSlice } = selector ? selector : {}

  console.log(authSlice)
  let { error, loading } = authSlice ? authSlice : {}
  const handleChange = (e) => {
    const { name, value } = e.target;
    setCredentials({ ...credentials, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(adminLogin(credentials)); // Dispatch the login action with the credentials
  };



  const handleCheckboxChange = () => {
    setIsChecked(!isChecked);
  };



  useEffect(() => {
    if (authSlice.user) {
      navigate('/app/dashboard');
    }
  }, [authSlice.user, navigate]);

  return (
    <div className="flex items-center justify-center h-screen bg-[#212B36] font">
      <Loader loading={loading} />
      <div className="w-full max-w-xl">
        <div className="bg-white  shadow-md p-10 h-[73vh]  rounded-[10px] font">

          <img className='m-auto mb-6 mt-4 h-8 w-44' src={admin} alt="" />


          <h2 className="text-2xl font-semibold text-[#202224] mb-2 text-center"
            style={{
              fontFamily: 'Nunito Sans',
              fontSize: '32px',
              lineHeight: '43.65px',
              // letter-spacing: -0.11428570002317429px;
              // text-align: left;
              // text-underline-position: from-font;
              // text-decoration-skip-ink: none;

            }}
          >Login to Account</h2>
          <p className='opacity-[80%] text-[14px] text-[#202224] text-center mb-6'
            style={{
              fontFamily: 'Nunito Sans',
              fontSize: '18px',
              lineHeight: '24.55px',
            }}
          >Please enter your email and password to continue</p>
          <form onSubmit={handleSubmit} className="">

            <div className='w-full'
              style={{
                fontFamily: 'Nunito Sans',
                fontSize: '18px',
                lineHeight: '24.55px',
              }}
            >
              <label htmlFor="email" className=" mb-3 text-sm block text-[#202224] opacity-[80%]">
                Email address:
              </label>
              <input
                type="text"
                id="email"
                name="email"
                placeholder="Enter your Email"
                value={credentials.email}
                onChange={handleChange}
                required
                className="text-[#A6A6A6] focus:outline-none focus:border-[#202224]  p-3 mb-4 h-12 bg-[#F1F4F9] border border-[#D8D8D8] rounded-[10px] w-full"
              />
            </div>

            <div className='w-full mb-2 mt-6'
            
            style={{
              fontFamily: 'Nunito Sans',
              fontSize: '18px',
              lineHeight: '24.55px',
            }}>
              <label htmlFor="password" className="mb-3 block text-sm   opacity-[80%] text-[#202224]">
                Password:
              </label>
              <input
                type="password"
                id="password"
                name="password"
                placeholder="Enter your password"
                value={credentials.password}
                onChange={handleChange}
                required
                className=" focus:outline-none focus:border-[#202224]  p-3 mb-4 h-12 bg-[#F1F4F9] border border-[#D8D8D8] rounded-[10px] w-full text-[#A6A6A6]"
              />
            </div>
            <div className='flex items-center space-x-2 mb-10'
               style={{
                fontFamily: 'Nunito Sans',
                fontSize: '18px',
                lineHeight: '24.55px',
              }}>
              <fieldset className="border-none p-0 m-0">

                <div className="flex flex-col space-y-2">
                  <label className="flex items-center cursor-pointer">
                    <input
                      type="checkbox"
                      checked={isChecked}
                      onChange={handleCheckboxChange}
                      className="hidden"
                    />
                    <span className={`w-5 h-5 border border-[#A3A3A3] rounded-lg flex items-center justify-center ${isChecked ? 'text-[#656565]' : 'bg-transparent'}`}>
                      {isChecked && <span className="text-[#656565]">✔</span>}
                    </span>
                    <span className="ml-2 text-[#202224]">Remember Password</span>
                  </label>
                </div>
              </fieldset>
            </div>


            <div className='w-full'
               style={{
                fontFamily: 'Nunito Sans',
                fontSize: '18px',
                lineHeight: '24.55px',
              }}>
              <button
                type="submit"
                disabled={loading}
                className={`bg-gradient-to-r from-[#855DF6] to-[#715EFE] font-semibold text-[#FFFFFF] py-3 mt-2 w-full rounded-[10px]  transition duration-300 ${loading ? 'opacity-50 cursor-not-allowed' : ''}`}
              >
                {loading ? 'Signing in...' : 'Sign in'}
              </button>
            </div>

            {error && <p className="text-red-500 mt-4">{error}</p>}
          </form>
        </div>
      </div>
    </div >
  );
};

export default Login;
