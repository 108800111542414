// authSlice.js
import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from '../_helpers/apiConfig';


// export const adminLogin = createAsyncThunk("auth/adminLogin", async (credentials) => {
//   const response = await apiRequest('POST', '/v1/admin/login', credentials);

//   console.log('responce--->',response)
//   return response; 
// });

export const adminLogin = createAsyncThunk("auth/adminLogin", async (credentials, { rejectWithValue }) => {
  try {
    const response = await apiRequest('POST', '/v1/admin/login', credentials);

    if (!response.data || !response.data.token) {
      throw new Error(response.message || 'Invalid credentials');
    }

    return response;
  } catch (error) {
  
    return rejectWithValue(error.response?.data?.message || error.message || 'Login failed');
  }
});

export const categoryManagement = createAsyncThunk("category/getCategoryList", async (filters) => {
  const response = await apiRequest('POST', '/v1/category/getCategoryList', filters);
  return response;
});


const authSlice = createSlice({
  name: 'auth',
  initialState: {
    category: [],
    total: null,
    loading: false,
    error: null,

  },
  reducers: {
    logout(state) {
      state.user = null;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(adminLogin.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(adminLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.user = action.payload;
        localStorage.setItem('accessToken', action.payload.data.token);
        localStorage.setItem('adminId', action.payload.data.userId);

      })
      .addCase(adminLogin.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(categoryManagement.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(categoryManagement.fulfilled, (state, action) => {
        state.loading = false;
        state.category = action.payload.data.list;
        state.total = action.payload.data.total;
      })
      .addCase(categoryManagement.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });

  },
});

// Export the actions and reducer
export const { logout } = authSlice.actions;
export default authSlice.reducer;
