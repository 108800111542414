import React from 'react'
import { BsPatchCheck } from 'react-icons/bs';

const SuccessModal = ({ title, message, isOpen, onClose, success }) => {


    if (!isOpen) return null;
    return (
        <div>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg shadow-lg w-1/4">
                    {/* Header */}
                    <div className="flex justify-between items-center border-b px-4 py-3">
                        <h3 className="text-lg font-semibold">{title}</h3>
                        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
                            &times;
                        </button>
                    </div>
                    {/* Body */}
                    <div className="p-6 text-center">
                        <div className="flex justify-center items-center mb-4">
                            {success && (
                                <div className="bg-green-100 rounded-full p-4 flex justify-center place-items-center">
                                    <BsPatchCheck className='text-[#34C759] text-2xl' />
                                </div>
                            )}
                        </div>
                        <p className="text-lg font-medium">{message}</p>
                    </div>
                    {/* Footer */}
                    <div className="flex justify-end border-t px-4 py-3 gap-3">
                        <button
                            onClick={onClose}
                            className="px-4 py-2 border rounded-lg hover:bg-gray-100"
                        >
                            Cancel
                        </button>
                        <button
                            onClick={onClose}
                            className="px-4 py-2 bg-gradient-to-r from-[#855DF6] to-[#715EFE] text-white rounded-lg hover:bg-purple-700 mr-2"
                        >
                            Done
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SuccessModal