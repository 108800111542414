import { configureStore } from '@reduxjs/toolkit'
import authReducer from "./userSlice"
import courseSlice from './courseSlice'
import expertSlice from './expertSlice'
import dashboardslice from './dashboardslice'
import notificationslice from './notificationslice'
import helpAndSupportSlice from './helpAndSupportSlice'
import appSettingSlice from './appSettingSlice'

export const store = configureStore({
  reducer: {
    authSlice: authReducer,
    courseSlice: courseSlice,
    expertSlice: expertSlice,
    dashboardslice: dashboardslice,
    notificationslice: notificationslice,
    helpAndSupportSlice: helpAndSupportSlice,
    appSettingSlice:appSettingSlice
  },
})