import React from 'react'
import InputField from '../../../components/ui/Input'




const CreateAppSettingModal = ({ onCancel, onSubmit, title, inputData, handleONChange, error }) => {
    return (
        <div>
            <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                <div className="bg-white rounded-lg shadow-lg w-[50rem]">
                    <div className='border-b w-full'>
                        <div className="flex justify-between items-center border-b pb-2 p-6">
                            <h3 className="text-lg font-semibold">{title}</h3>
                            <button
                                className="text-gray-500 text-2xl w-10 h-10 bg-gray-100 rounded-full  hover:text-gray-800"
                                onClick={onCancel}
                            >
                                &times;
                            </button>
                        </div>
                    </div>
                    <div className=' p-4'>
                        <div className='grid grid-cols-2 gap-4 items-center'>
                            <div>
                                <InputField label={`Name`} name='name' value={inputData?.name} onChange={handleONChange} />
                                {error?.name && <p className="text-red-500 text-sm">{error?.name}</p>}
                            </div>
                            <div>
                                <InputField label={`key`} name='key' value={inputData?.key} onChange={handleONChange} />
                                {error?.key && <p className="text-red-500 text-sm">{error?.key}</p>}
                            </div>
                        </div>
                        <InputField label={`Value`} name='value' value={inputData?.value} onChange={handleONChange} />
                        {error?.value && <p className="text-red-500 text-sm">{error?.value}</p>}
                
                    </div>


                    <div className="flex justify-end space-x-3 mt-6 border-t p-4">
                        <button
                            className="px-6 py-2 border border-[#B8B8D2] text-gray-700 rounded hover:bg-gray-300"
                            onClick={onCancel}
                        >
                            Cancel
                        </button>
                        <button
                            className="px-6 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
                            onClick={onSubmit}
                        >
                            {title==='Update App Settings'?"Update":"Create"} 
                        </button>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default CreateAppSettingModal
