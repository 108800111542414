import React, { useEffect, useState } from 'react'
// import { useDispatch, useSelector } from 'react-redux';
// import { expertManagement } from '../../Redux/expertSlice';
// import moment from 'moment';

// import Pagination from '../../components/Pagination/Pagination';
import ContentTable from './Table/ContentTable';
// import ViewModal from '../user managment/LearnerModal/ViewModal';
import DeletModal from '../../components/Delete Modal/DeletModal';
import ContentViewModal from './Content View/ContentViewModal';

function Contentmanagement() {
    // const dispatch = useDispatch();
    // const selector = useSelector(state => state);
    const [isMenu, setIsMenu] = useState(false)
    const [clickedUserId, setClickedUserId] = useState(null);
    const [isViewModal, setIsViewModal] = useState(false)
    const [isActionModal, setStatusActionModal] = useState(false)
    const [contentValue, setContentValue] = useState(null)



    const TbaleHeader = ['#', 'Content id', 'Uploaded By', ' Content Type ', 'Status', 'Upload Date', 'Flagging Date', 'Action',]
    const TbaleData = [
        {
            _id: 1,
            image: "/image 2.png",
            contentId: "C001",
            uploadBy: "Pankaj",
            type: 1,
            uploadDate: "10 Dec 2024",
            flacgingDate: "10 Dec 2024",
            isActive: true,
        },
        {
            _id: 2,
            contentId: "C002",
            uploadBy: "Pankaj",
            type: 1,
            uploadDate: "10 Dec 2024",
            flacgingDate: "10 Dec 2024",
            isActive: true,
        }, {
            _id: 3,
            contentId: "C003",
            uploadBy: "Pankaj",
            type: 1,
            uploadDate: "10 Dec 2024",
            flacgingDate: "10 Dec 2024",
            isActive: true,
        },

    ]


    const showMenu = (userId) => {
        if (clickedUserId === userId) {
            setIsMenu(!isMenu);
        } else {
            setClickedUserId(userId);
            setIsMenu(true);
        }
    };

    const viewAction = (data) => {
        setIsViewModal(!isViewModal)
        setContentValue(data)
    }

    const toggleViewModal = () => { setIsViewModal(!isViewModal); setContentValue(null) }



    const removeContent = () => {
        setStatusActionModal(!isActionModal)
    }
    const toggleStatusModal = () => {
        setStatusActionModal(!isActionModal)

    }
    const handleSubmit = () => {

    }
    return (
        <>



            <section>
                <div className="bg-gradient-to-r from-[#855DF6] to-[#715EFE]  h-[150px] shadow-lg  relative">
                    <h2 className="text-2xl font-bold  text-white dahboardFont p-10 px-12">Content Management</h2>
                    <div className="  gap-4 absolute w-full mx-auto p-14 top-11 right-1">
                        <ContentTable TbaleName='Content Review Table' TbaleHeader={TbaleHeader} TbaleData={TbaleData} showMenu={showMenu}
                            isMenu={isMenu} clickedUserId={clickedUserId} viewAction={viewAction} removeContent={removeContent} />
                    </div>
                </div>
            </section>

            {
                isViewModal && <>
                    <ContentViewModal isOpen={isViewModal} onCancel={toggleViewModal}   inputValue={contentValue} title={`Preview Content`}/>
                </>
            }
            {
                isActionModal && (
                    <DeletModal isOpen={isActionModal} buttonName={`Remove`} title={`Remove Modal`} onCancel={toggleStatusModal} title1={`are you sure want to remove`} onDelete={handleSubmit} description={`this action cant not revert`} />
                )
            }

        </>
    );
}

export default Contentmanagement