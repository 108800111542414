import React, { Suspense } from 'react';
import { Route, Routes } from "react-router-dom";
import Header from "../Header/Header";
import Sidebar from "../Sidebar/Sidebar";
import layoutJSON from "./layout.json";
import UserMangment from '../../pages/user managment/UserManagment'
import Contentmanagement from '../../pages/Content management/Contentmanagement';
import SessionMangment from '../../pages/Session Mangment/SessionMangment';
import CommunityMangment from '../../pages/Coummunity Mangment/CommunityMangment';
import PaymentsAndTransactions from '../../pages/Payments & Transactions/PaymentsAndTransactions';
import AdsAndPromotation from '../../pages/Ads And Promotation/AdsAndPromotation';
import CertificateMangment from '../../pages/Certificate Mangment/CertificateMangment';
import ReferalRewardMangment from '../../pages/Referal Reward/ReferalRewardMangment';
import PreferenceMangment from '../../pages/Preference Mangment/PreferenceMangment';
import AdminMangment from '../../pages/Admin/AdminMangment';
import AppSetting from '../../pages/App Setting/AppSetting';
const Dashboard = React.lazy(() => import("../../pages/dashboard/Dashboard"))
// const Coursemanagement = React.lazy(() => import("../../pages/Course management/Coursemanagement"))
const Analytics = React.lazy(() => import("../../pages/Analytics & reports/Analytics"))
const Helpsupport = React.lazy(() => import("../../pages/Help & support/Helpsupport"))
const Notification = React.lazy(() => import("../../pages/Notifications/Notification"))



function Layout() {
  const [navbarOpen, setNavbarOpen] = React.useState(true);
  const handleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };
  return (
    <div>
      <div className="overflow-hidden bg-gray-100">
        <div className="min-h-screen flex flex-col justify-center">
          <div className="h-screen flex justify-between  relative ">
            <div
              className={`h-full ${navbarOpen ? "w-64 absolute md:relative transition ease-in-out delay-150" : "w-0 "
                }`}
            >
              <Sidebar
                SidebarJSON={layoutJSON.sidebarJSON}
                navbarOpen={navbarOpen}
                setNavbarOpen={setNavbarOpen}
              />
            </div>

            <div
              className={`flex flex-col ${navbarOpen ? " flex-1  " : "w-full"
                }   overflow-hidden`}
            >
              <Header HeaderJSON={layoutJSON.headerJSON} handleNavbar={handleNavbar} />

              <Suspense fallback={<div>Loading...</div>}>
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route path="/users" element={<UserMangment />} />
                  <Route path="/content" element={<Contentmanagement />} />
                  <Route path="/session" element={<SessionMangment />} />
                  <Route path="/community" element={<CommunityMangment />} />
                  <Route path="/payments&transactions" element={<PaymentsAndTransactions />} />
                  <Route path="/adsAndPromotation" element={<AdsAndPromotation />} />
                  <Route path="/certificate" element={<CertificateMangment />} />
                  {/* <Route path="/course" element={<Coursemanagement />} /> */}
                  <Route path="/referal/reward" element={<ReferalRewardMangment />} />
                  <Route path="/preferencepanagement" element={<PreferenceMangment />} />
                  <Route path="/admin" element={<AdminMangment />} />
                  <Route path="/appSetting" element={<AppSetting />} />
                  <Route path="/analytics" element={<Analytics />} />
                  <Route path="/notifications" element={<Notification />} />
                  <Route path="/helpsupport" element={<Helpsupport />} />
                </Routes>
              </Suspense>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Layout;
