import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from '../_helpers/apiConfig';

export const dashboardCardCounts = createAsyncThunk("admin/getDashboardCardCounts", async (filters) => {
  const response = await apiRequest('POST', '/v1/admin/getDashboardCardCounts', filters);
  return response;
});

export const latestStudentSignUp = createAsyncThunk("user/list", async (filters) => {
  const response = await apiRequest('POST', '/v1/user/list', filters);
  return response;
});


const dashboardslice = createSlice({
  name: 'dashboard',
  initialState: {
    cardCounts: {},
    latestSignUp: [],
    total: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(dashboardCardCounts.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(dashboardCardCounts.fulfilled, (state, action) => {
        state.loading = false;
        state.cardCounts = action.payload.data;
      })
      .addCase(dashboardCardCounts.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })
      
      .addCase(latestStudentSignUp.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(latestStudentSignUp.fulfilled, (state, action) => {
        state.loading = false;
        state.latestSignUp = action.payload.data.list;
      })
      .addCase(latestStudentSignUp.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default dashboardslice.reducer;
