import React, { useState } from 'react'
import Button from '../../components/Button/Button'
import Card from '../../components/Card/Card'
import card1icon from "../../public/images/card1.svg"
import card2icon from "../../public/images/card2.svg"
import card3icon from "../../public/images/card3.svg"
import card4icon from "../../public/images/card4.svg"
import ReferalTable from './Table/ReferalTable'
import RewardTable from './Table/RewardTable'
import DeletModal from '../../components/Delete Modal/DeletModal'
import ReferalCreateModal from './Modal/ReferalCreateModal'
import RewardCreateModal from './Modal/RewardCreateModal'

const feildsVlaue={rewardname:"",rewardValue:"",condition:""}

const ReferalRewardMangment = () => {
  const [activeTab, setActiveTab] = useState('ReferralCodeManagement')
  const [isDeleteModal, setIsDeleteModal] = useState(false)
  const [isCreateReferalModal, setIsCreateReferalModal] = useState(false)
  const [isCreateRewardModal, setIsCreateRewardModal] = useState(false)
  const [rewardCreateInputs,setRewardsCreateInput]=useState(feildsVlaue)

  const referalThead = ['#', 'Referral Code', 'Referred User', 'Status', 'Associated Reward', 'Usage Count', 'Action'];
  const rewardThead = ['#', 'Reward Name', 'Reward Type', 'Status', 'Associated Reward', 'Conditions', 'Action'];
  const referalData = [{ id: 1, code: 'REF123', user: 'Pankaj', status: true, reward: "10% Discount", count: 3 },

  { id: 2, code: 'REF123', user: 'Pankaj', status: false, reward: "10% Discount", count: 3 }
  ]
  const rewardData = [{ id: 1, name: 'SUMO123', type: 'Discount', status: true, reward: "10% Discount", Conditions: 'none' },]

  const handleToggle = (data) => {

  };
  const deletAction = (data) => {
    setIsDeleteModal(!isDeleteModal)
  }
  const toggleDeletModal = () => { setIsDeleteModal(!isDeleteModal) }
  const handleCreateReferal = (identify) => {
    { identify === 'REFERAL' ? setIsCreateReferalModal(!isCreateReferalModal) : setIsCreateRewardModal(!isCreateRewardModal) }
  }
  const toggleReferalCreateModal=()=>{setIsCreateReferalModal(!isCreateReferalModal)}
  const toggleRewardCreateModal=()=>{setIsCreateRewardModal(!isCreateRewardModal)}

  const handleOnChnage=(e)=>{
    const {name,value}=e.target
    setRewardsCreateInput({...rewardCreateInputs,[name]:value})
  }


  return (
    <div>
      <div className="bg-gradient-to-r from-[#855DF6] to-[#715EFE] h-40  shadow-lg  relative">
        <h2 className="text-2xl font-bold  text-white dahboardFont px-7 pt-5">Referral & Rewards System Management</h2>
        <div className="grid lg:grid-cols-4 grid-cols-1 md:grid-cols-4  gap-4 absolute w-full mx-auto p-8 top-11 right-1">
          <Card title={'Total Referrals'} content={30} img={card1icon} number={0 || 0} />
          <Card title={'Successful Referrals'} content={20} img={card2icon} number={0 || 0} />
          <Card title={'Total Rewards Issued'} content={20} img={card3icon} number={0 || 0} />
          <Card title={'Conversion Rate'} content={20} img={card4icon} number={`$69`} />

        </div>
      </div>
      <section className="p-6 mt-20 md:p-10">

        <div className="flex relative space-x-3 h-10 w-full rounded-t-md border border-t-0 border-x-0 border-b-2 cursor-pointer">
          <Button
            label="Referral Code Management"
            isActive={activeTab === "ReferralCodeManagement"}
            onClick={() => setActiveTab("ReferralCodeManagement")}
          />
          <Button
            label="Reward Customization"
            isActive={activeTab === "RewardCustomization"}
            onClick={() => setActiveTab("RewardCustomization")}
          />

        </div>
        <div className='mt-4'>

          {
            activeTab === 'ReferralCodeManagement' ?
              <ReferalTable TbaleName={`Generate and Track Referral Codes`} TbaleHeader={referalThead} TbaleData={referalData} handleToggle={handleToggle}
                deletAction={deletAction} handleCreateModal={handleCreateReferal} />
              :
              <RewardTable TbaleName={`Reward Customization`} TbaleHeader={rewardThead} TbaleData={rewardData} handleToggle={handleToggle} handleCreateModal={handleCreateReferal} />
          }
        </div>
      </section>
      {
        isDeleteModal && (<DeletModal isOpen={isDeleteModal} title={`Delete Modal`} onCancel={toggleDeletModal} />)
      }
      {
        isCreateReferalModal && (<ReferalCreateModal onCancel={toggleReferalCreateModal} title={`Generate New Referral Code`}/>)
      }
      {
        isCreateRewardModal && (<RewardCreateModal onCancel={toggleRewardCreateModal} title={`Add New Referral Reward`} inputData={rewardCreateInputs} handleONChange={handleOnChnage}/>)
      }
    </div>
  )
}

export default ReferalRewardMangment