// apiConfig.js
import axios from 'axios';
import { baseURL } from '../BaseURL';


const apiClient = axios.create({
  baseURL: baseURL,
});

export const setHeaders = () => {
  let user = localStorage.getItem('accessToken');

  return {
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + user
    },
  };
};

export function logoutFunction() {
  localStorage.removeItem('accessToken');

  window.location.replace('/login');
  window.location.reload()
}




export const apiRequest = async (method, endpoint, data ) => {


  let user = localStorage.getItem('accessToken');

  try {
    const response = await apiClient({
      method: method,
      url: endpoint,
      data: data,
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + user
      },
    });


    return response.data;
  } catch (error) {
    throw error.response ? error.response.data : { message: 'Unknown error occurred' };
  }
};
