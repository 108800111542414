import React from "react";
import { IoEyeOutline } from "react-icons/io5";
import { LiaEdit } from "react-icons/lia";
import { CiFilter } from "react-icons/ci";
import moment from "moment";
// import { MdDeleteForever } from "react-icons/md";
// import { FaUserCheck } from "react-icons/fa";
// import { FiUserX } from "react-icons/fi";

const ExpertMangmentTable = ({
    TableName,
    TableHeader,
    TableData,
    handleDelete,
    handleView,
    handleEdit,
    handleUserBlock,
    handleFilter,
    handleSearch,

    total,
    pageNo,
    size,
}) => {


    // const totalPages = Math.ceil(total / size);

    return (
        <div>
            <div className="bg-white rounded-md shadow-md">
                <div className="w-full flex justify-between place-content-center">
                    <h2 className="text-xl font-bold inter500 p-6">
                        {TableName}
                    </h2>
                    <div className="flex justify-between place-items-center gap-2 mx-2">
                        <input
                            type="text"
                            placeholder="Search...."
                            className="border h-10 px-2 rounded shadow"
                            onChange={handleSearch}
                        />
                        <div className="relative inline-flex items-center border rounded-md shadow bg-white">
                            <div className="px-3 py-2">
                                <CiFilter className="text-lg" />
                            </div>
                            <select className="appearance-none w-full px-3 py-2 bg-transparent border-none focus:ring-0 focus:outline-none" onClick={handleFilter}>
                                <option value="" disabled>
                                    Filter
                                </option>
                                <option value="">Filter</option>
                                <option value="ACTIVE">Active</option>
                                <option value="INACTIVE">Inactive</option>
                                <option value="BLOCK">Block</option>
                            </select>
                        </div>
                    </div>
                </div>

                <div className="overflow-x-auto">
                    <table className="w-full border-collapse">
                        <thead className="bg-[#F1F5F9]">
                            <tr>
                                {TableHeader.map((heading, index) => (
                                    <td
                                        key={index}
                                        className="py-2.5 px-4 capitalize inter500 text-center"
                                    >
                                        {heading}
                                    </td>
                                ))}
                            </tr>
                        </thead>

                        <tbody>
                            {TableData && TableData.length > 0 ? (
                                TableData.map((learner, index) => (
                                    <tr key={index} className="hover:bg-gray-100">
                                        <td className="py-2 ps-6 border-b">
                                            {(pageNo - 1) * size + index + 1}
                                        </td>
                                        <td className="py-2 ps-5 border-b inter500 text-[#0F172A]">
                                            <div className="flex justify-start items-center gap-3">
                                                <img
                                                    src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
                                                    alt="Avatar"
                                                    className="h-10 w-10 rounded-full"
                                                />
                                                <p>{learner.fullName}</p>
                                            </div>
                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569] ">
                                            {learner.courseCreated}
                                        </td>

                                        <td className="py-2 px-4 border-b text-[#475569]">
                                            0
                                        </td>
                                        <td className="py-2 px-4 border-b text-[#475569]">
                                            {learner?.averageRating || '0'}
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <button
                                                className={`px-4 py-1 rounded-full ${learner.isBlocked
                                                    ? "bg-[#FF3B3033]/20 text-[#FF3B30]"
                                                    : learner.isActive
                                                        ? "bg-[#CDF3E5] text-[#43A581]"
                                                        : "bg-[#FFF0D4] text-[#FBB000]"
                                                    }`}
                                            >
                                                {learner.isBlocked? "Blocked": learner.isActive
                                                        ? "Active"
                                                        : "Inactive"}
                                            </button>
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            {learner.createdAt
                                                ? moment(learner.createdAt).format(
                                                    "DD.MM.YYYY - hh.mm A"
                                                )
                                                : "-"}
                                        </td>
                                        <td className="py-2 px-4 border-b">
                                            <div className="flex justify-start items-center gap-2">
                                                <button
                                                    className="bg-[#E5E5EA] p-2 rounded flex justify-center"
                                                    onClick={() => handleView(learner)}
                                                >
                                                    <IoEyeOutline className="text-lg" />
                                                </button>
                                                <button
                                                    className="bg-[#E5E5EA] p-2 rounded flex justify-center"
                                                    onClick={() => handleEdit(learner)}
                                                >
                                                    <LiaEdit className="text-lg" />
                                                </button>

                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <tr>
                                    <td colSpan="8" className="py-4 text-[#475569]">
                                        No data found.
                                    </td>
                                </tr>
                            )}
                        </tbody>
                    </table>
                </div>


            </div>
        </div>
    );
};

export default ExpertMangmentTable;
















































// import { IoEyeOutline } from "react-icons/io5";
// import { LiaEdit } from "react-icons/lia";
// import { CiFilter } from "react-icons/ci";
// import moment from 'moment';
// import { MdDeleteForever } from 'react-icons/md';
// import { FaUserCheck, FaUserLargeSlash } from 'react-icons/fa6';
// import { LuUserCheck } from 'react-icons/lu';
// import { FiUserX } from "react-icons/fi";


// const LearningMangmentTable = ({ TableName, TableHeader, TableData, offset, handleDelete, handleView, handleEdit, handleUserBlock, handleSearch }) => {

//     console.log('tabeklDataaaa', TableData)

//     return (
//         <div>
//             <div className=" bg-white rounded-md shadow-md">
//                 <div className='w-full flex justify-between place-content-center  '>
//                     <h2 className="text-xl font-bold inter500 p-[10.5px]  text-[20px]">{TableName}</h2>
//                     <div className=" flex justify-between place-items-center gap-2 mx-2">
//                         <input
//                             type="text"
//                             placeholder="Search...."
//                             className="border h-10 px-2  rounded shadow "
//                             onChange={handleSearch}
//                         />
//                         <div className="relative inline-flex items-center border rounded-md  shadow bg-white">
//                             <div className="px-3 py-2 ">
//                                 <CiFilter className="text-lg" />
//                             </div>
//                             <select

//                                 className="appearance-none w-full px-3 py-2  bg-transparent border-none focus:ring-0 focus:outline-none"
//                             >
//                                 <option value="Filter" disabled>
//                                     Filter
//                                 </option>
//                                 <option value="Option 1">Filter</option>
//                                 <option value="Option 2">Active</option>
//                                 <option value="Option 3">Inactive</option>
//                                 <option value="Option 3">Block</option>

//                             </select>
//                         </div>
//                     </div>
//                 </div>


//                 <div className="overflow-x-auto">
//                     <table className="w-full border-collapse">
//                         <thead className="bg-[#F1F5F9]">
//                             <tr>
//                                 {TableHeader.map((heading, index) => (
//                                     <td
//                                         key={index}
//                                         className="py-2.5 px-4 capitalize inter500"
//                                     >
//                                         {heading}
//                                     </td>
//                                 ))}
//                             </tr>
//                         </thead>

//                         <tbody>
//                             {TableData && TableData.length > 0 ? (
//                                 TableData.map((learner, index) => (
//                                     <tr key={index} className="hover:bg-gray-100">
//                                         <td className="py-2 ps-6 border-b">{offset + index + 1}</td>
//                                         <td className="py-2 ps-5 border-b inter500 text-[#0F172A]">
//                                             <div className="flex justify-start items-center gap-3">
//                                                 <img
//                                                     src="https://www.gravatar.com/avatar/2c7d99fe281ecd3bcd65ab915bac6dd5?s=250"
//                                                     alt="Avatar"
//                                                     className="h-10 w-10 rounded-full"
//                                                 />
//                                                 <p>{learner.fullName}</p>
//                                             </div>
//                                         </td>
//                                         <td className="py-2 px-4 border-b text-[#475569] text-center">
//                                             {learner.purchaseCount}
//                                         </td>
//                                         <td className="py-2 px-4 border-b text-[#475569] text-center">0</td>
//                                         <td className="py-2 px-4 border-b text-[#475569] text-center">0</td>
//                                         <td className="py-2 px-4 border-b text-center">
//                                             <button
//                                                 className={`px-4 py-1 rounded-full ${learner.isBlocked
//                                                     ? "bg-[#FF3B3033]/20 text-[#FF3B30]" // Blocked
//                                                     : learner.isActive
//                                                         ? "bg-[#CDF3E5] text-[#43A581]" // Active
//                                                         : "bg-[#FFF0D4] text-[#FBB000]" // Inactive
//                                                     }`}
//                                             >
//                                                 {learner.isBlocked ? "Blocked" : learner.isActive ? "Active" : "Inactive"}
//                                             </button>
//                                         </td>

//                                         <td className="py-2 px-4 border-b text-center">
//                                             {learner.createdAt
//                                                 ? moment(learner.createdAt).format('DD.MM.YYYY - hh.mm A')
//                                                 : '-'}
//                                         </td>

//                                         <td className="py-2 px-4 border-b">
//                                             <div className="flex justify-center items-center gap-2">
//                                                 <button className="bg-[#E5E5EA] p-2 rounded flex justify-center" onClick={() => handleView(learner)}>
//                                                     <IoEyeOutline className="text-lg" />
//                                                 </button>
//                                                 <button className="bg-[#E5E5EA] p-2 rounded flex justify-center" onClick={() => handleEdit(learner)}>
//                                                     <LiaEdit className="text-lg" />
//                                                 </button>
//                                                 <button className="bg-[#E5E5EA] p-2 rounded flex justify-center"
//                                                     onClick={() => handleDelete(learner)}
//                                                 >
//                                                     <MdDeleteForever className="text-lg" />
//                                                 </button>
//                                                 <button className="bg-[#E5E5EA] p-2 rounded flex justify-center"
//                                                     onClick={() => handleUserBlock(learner)}
//                                                 >
//                                                     {learner?.isActive === true ? (
//                                                         <span><FiUserX /></span>
//                                                     ) : (
//                                                         <LuUserCheck />
//                                                     )}
//                                                 </button>
//                                             </div>
//                                         </td>
//                                     </tr>
//                                 ))
//                             ) : (
//                                 <tr>
//                                     <td colSpan="8" className="py-4 text-center text-[#475569]">
//                                         No data found.
//                                     </td>
//                                 </tr>
//                             )}
//                         </tbody>

//                     </table>
//                 </div>

//             </div>
//         </div>
//     )
// }

// export default LearningMangmentTable