import React from 'react'


const ContentAction = ({ viewAction, removerContent }) => {
    return (
        <div>
            <div>
                <div>
                    <div className='bg-white border shadow  rounded-xl  w-40 '>
                        <ul className=''>

                            <li className='hover:bg-blue-700 hover:text-white border-b p-2 gap-1' onClick={viewAction}>
                           
                               Preview Content
                            </li>
                            <li className='hover:bg-blue-700 hover:text-white p-2 gap-1' onClick={removerContent}>
                             
                                Remove Content

                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ContentAction