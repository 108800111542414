import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { apiRequest } from '../_helpers/apiConfig';


export const userListForNotification = createAsyncThunk("user/list", async (filters) => {
  const response = await apiRequest('POST', '/v1/user/list', filters);
  return response;
});

export const createNotification = createAsyncThunk("notification/createNotification", async (filters) => {
  const response = await apiRequest('POST', '/v1/notification/createNotification', filters);
  return response;
});

export const notificationList = createAsyncThunk("notification/getNotificationList", async (filters) => {
  const response = await apiRequest('POST', '/v1/notification/getNotificationList', filters);
  return response;
});


const notificationslice = createSlice({
  name: 'dashboard',
  initialState: {
    userList: [],
    notificationListData: [],
    notificationTotal: null,
    notificationMsg: "",
    total: null,
    loading: false,
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder

      .addCase(userListForNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(userListForNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.userList = action.payload.data.list;
      })
      .addCase(userListForNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(createNotification.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(createNotification.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationMsg = action.payload.message;
      })
      .addCase(createNotification.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      })

      .addCase(notificationList.pending, (state) => {
        state.loading = true;
        state.error = null;
      })
      .addCase(notificationList.fulfilled, (state, action) => {
        state.loading = false;
        state.notificationListData = action.payload.data.list;
        state.notificationTotal = action.payload.data.total;
      })
      .addCase(notificationList.rejected, (state, action) => {
        state.loading = false;
        state.error = action.error.message;
      });
  },
});

export default notificationslice.reducer;
