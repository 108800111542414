import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { expertManagement, learnerManagement } from '../../Redux/expertSlice';
import { FaExchangeAlt } from "react-icons/fa";
import card1icon from "../../public/images/card1.svg"
import card2icon from "../../public/images/card2.svg"
import card3icon from "../../public/images/card3.svg"
import card4icon from "../../public/images/card4.svg"
import LearningMangmentTable from './Table/UserMangmentTable';
import DeletModal from '../../components/Delete Modal/DeletModal';
import ViewModal from './LearnerModal/ViewModal';
import EditModal from './LearnerModal/EditModal';
import Button from '../../components/Button/Button';
import Card from '../../components/Card/Card';
import ExpertMangmentTable from './Table/ExpertMangmentTable.js';
import Loader from '../../components/Loader/Loader.js';

function Learnermanagement() {
  const dispatch = useDispatch();
  const selector = useSelector(state => state);
  const [pageNo, setPageNo] = useState(1);
  const [size, setSize] = useState(5);
  // const [offset, setOffset] = useState(0);
  // const [keyWord, setKeyWord] = useState(0);
  const [activeTab, setActiveTab] = useState('LearnersManagement');

  const { expertSlice } = selector ? selector : {}
  let { loading, learners, total,experts } = expertSlice ? expertSlice : {}

  console.log(experts)


  const [isViewModal, setIsViewModal] = useState(false)
  const [isEditModal, setIsEditModal] = useState(false)
  const [isBlockModal, setIsBlockModal] = useState(false)


  const [ViewModalData, setIsViewModalData] = useState({})
  const [editData, setEditData] = useState({})
  const [blockData, setBlockData] = useState({})

  const [isMenu, setIsMenu] = useState(false)
  const [clickedUserId, setClickedUserId] = useState(null)


  const expertTableHeader = ['#', 'Expert Name', 'Courses Created', 'Learners Enrolled ', 'Ratings (Avg) ', 'Status', 'Join Date', 'Action']
  const learnerTableHead = ['#', 'Learner Name', 'User id', 'Course Enrolled', 'Course Completed', 'Status', 'Join Date', 'Action']



  useEffect(() => {

    let apiRequestData = {
      "keyWord": "",
      "isExpert": false,
      "fromDate": "",
      "toDate": "",
      "sortBy": "",
      "sortOrder": "asc",
      "pageNo": pageNo,
      "size": size,
      "status": ""
    }

    dispatch(learnerManagement(apiRequestData));
    let apiRequestData2 = {
      "keyWord": "",
      "isExpert": true,
      "fromDate": "",
      "toDate": "",
      "sortBy": "",
      "sortOrder": "asc",
      "pageNo": 1,
      "size": 5,
      "status": ""
    }

    dispatch(expertManagement(apiRequestData2));

  }, []);





  const toggleExpertViewModal = () => {
    setIsViewModal(!isViewModal)
  }

  const handleEdit = (data) => {
    setIsEditModal(!isEditModal)
    setEditData(data)
  }

  const toggleEditModal = () => {
    setIsEditModal(!isEditModal)
  }
  const handleONChange = (e) => {
    const { name, value } = e.target;
    setEditData({
      ...editData, [name]: value
    })
  }

  const onSubmit = (e) => {
    e.preventDefault()
    let editDataCred = {
      fullName: editData?.fullName || null
    }
    console.log(editDataCred)
  }

  const handleUserBlock = (data) => {
    setBlockData(data)
    setIsBlockModal(!isBlockModal)

  }


  const toggleBlockModal = () => {
    setIsBlockModal(!isBlockModal)
  }
  const handleBlockSubmit = () => {
    let blockCred = {
      id: blockData?.id,
      isBlock: blockData?.isActive ? false : true
    }
    console.log(blockCred)
  }



  const showMenu = (userId) => {
    if (clickedUserId === userId) {
      setIsMenu(!isMenu);
    } else {
      setClickedUserId(userId);
      setIsMenu(true);
    }
  };

  const handleExpertView = (data) => {
    setIsViewModal(true)
    setIsViewModalData(data)
  };
  const handleExpertEdit = (data) => {

  }

  return (
    <>
      <Loader loading={loading} />
      <div className="bg-gradient-to-r from-[#855DF6] to-[#715EFE] h-40  shadow-lg  ">
        <h2 className="text-2xl font-bold  text-white dahboardFont px-7 pt-5">User Management</h2>
        <div className="grid lg:grid-cols-4 grid-cols-1 md:grid-cols-4  gap-4  w-full mx-auto p-8 top-11 right-1">
          <Card title={'Total Learners'} content={30} img={card1icon} number={0 || 0} />
          <Card title={'Total Experts'} content={20} img={card2icon} number={0 || 0} />
          <Card title={'Active Learners'} content={20} img={card3icon} number={0 || 0} />
          <Card title={'Active Experts '} content={20} img={card4icon} number={`$69`} />

        </div>
      </div>
      <section className="p-6 mt-20 md:p-10">
        <div className="flex relative space-x-3 h-10 w-full rounded-t-md border border-t-0 border-x-0 border-b-2 cursor-pointer">
          <Button
            label="Learners Management"
            isActive={activeTab === "LearnersManagement"}
            onClick={() => setActiveTab("LearnersManagement")}
          />
          <Button
            label="Experts Management"
            isActive={activeTab === "ExpertsManagement"}
            onClick={() => setActiveTab("ExpertsManagement")}
          />

        </div>
        <div className='pt-6'>
          {
            activeTab === 'LearnersManagement' ?
              <>
                <LearningMangmentTable TableHeader={learnerTableHead} TableName={`Learner List`} TableData={learners} isMenu={isMenu} clickedUserId={clickedUserId} showMenu={showMenu} total={total} size={size} handleEdit={handleEdit} handleUserBlock={handleUserBlock} />
              </> : <>
                <ExpertMangmentTable TableHeader={expertTableHeader} TableName={`Experts List`} TableData={experts} handleView={handleExpertView} handleEdit={handleExpertEdit} />
              </>
          }
        </div>
      </section>




      {
        isEditModal && (
          <EditModal onCancel={toggleEditModal} EditData={editData} handleONChange={handleONChange} onSubmit={onSubmit} title={`Edit learner`} />
        )
      }

      {
        isBlockModal && <DeletModal title={`Block User`} title1={null} isOpen={isBlockModal} buttonName={`Remove`} icon={<FaExchangeAlt className="text-4xl text-purple-600" />} onCancel={toggleBlockModal} onDelete={handleBlockSubmit} />
      }
      {
        isViewModal && (
          <ViewModal isOpen={isViewModal} ViewModalData={ViewModalData} onCancel={toggleExpertViewModal} title={ViewModalData?.fullName} />
        )
      }
    </>
  );
}

export default Learnermanagement