import React, { useState } from 'react'
import { IoAddOutline } from 'react-icons/io5';
import { RiDeleteBinLine } from 'react-icons/ri';
import { TbEdit } from 'react-icons/tb';
import CreateModal from './Modal/CreateModal';
import EditModal from './Modal/EditModal';
// import Button from '../../components/Button/Button';
const createFeilds = { name: '', roleType: "", email: "", password: "", permissions: [] }
const multiSelectOptions = [
    { value: 'read', label: 'User Management' },
    { value: 'write', label: 'Dashboard Analytics' },
    { value: 'delete', label: 'Ads & Promotion Management' },
];
function AdminMangment() {
    const [isCreateAdminModal, setIsCreateAdminModal] = useState(false)
    const [isAdminEditModal, setIsAdminEditModal] = useState(false)
    const [inputData, setInputData] = useState(createFeilds)
    const [editData, setEditData] = useState(null)
    const [error, setError] = useState({})
    const handleCreateValidate = () => {
        let newError = {}
        if (!inputData?.name?.trim()) newError.name = 'Name is required';
        if (!inputData?.roleType?.trim()) newError.roleType = 'Role type is required';
        if (!inputData?.password?.trim()) newError.password = 'Password is required';
        if (!inputData?.email?.trim()) newError.email = 'Email is required';
        if (inputData?.permissions?.length === 0) { newError.permissions = 'At least one permission is required.' }
        setError(newError);
        return Object.keys(newError).length === 0
    }
    const users = [
        {
            id: 1,
            name: "John Doe",
            roleType: "Sub Admin",
            email: "alice@walker.com",
            password: "1234@walker.com",
            avatar: "https://via.placeholder.com/50",
            permissions: ["Dashboard Analytics", "User Management", "Ads & Promotion Management"],
        },
        {
            id: 2,
            name: "John Doe",
            roleType: "Sub Admin",
            email: "alice@walker.com",
            password: "1234@walker.com",
            avatar: "https://via.placeholder.com/50",
            permissions: ["Dashboard Analytics", "User Management", "Ads & Promotion Management"],
        },
    ];
    const handleCreateAdmin = () => { setIsCreateAdminModal(!isCreateAdminModal) }
    const toggleCreateModal = () => { setIsCreateAdminModal(!isCreateAdminModal); setInputData(''); setError(null) }
    const handleCreateOnChange = (e) => {
        const { name, value } = e.target; setInputData({ ...inputData, [name]: value })
        if (error[name]) {
            setError({ ...error, [name]: '' });
        }
    }
    const handleMultiSelectChange = (selectedOptions) => {
        setInputData({ ...inputData, permissions: selectedOptions || [] });
        if (error?.permissions) {
            setError({ ...error, permissions: '' });
        }
    };
    const handleCreateSubmit = (e) => {
        e.preventDefault()
        if (!handleCreateValidate()) {
            let payload = {
                name: inputData?.name
            }
            console.log(payload)
        }
    }
    const handleEditAdmin = (data) => {
        console.log(data)
        setIsAdminEditModal(!isAdminEditModal)
        setEditData(data)
    }
    const toggleEditModal = () => { setIsAdminEditModal(!isAdminEditModal) }
    const handleAdminEditOnChange = (e) => {const { name, value } = e.target; setEditData( {...editData, [name]: value }) }
    return (
        <div className='h-screen overflow-y-auto'>
            <section className=' h-52 lg:pt-16 pt-10 bg-gradient-to-r from-[#855DF6] to-[#715EFE] '>
                <div className='2xl:w-11/12 w-10/12 lg:w-11/12 mx-auto'>
                    <div className='flex justify-between place-items-center'>
                        <h2 className='font-bold md:text-3xl text-xl text-[#fff]'>Preference Management</h2>
                        <button className='bg-white rounded-md text-center p-2 transition-colors duration-300 flex justify-center place-items-center gap-2' onClick={handleCreateAdmin}>
                            <span><IoAddOutline /></span>
                            <span>Create New Role</span>
                        </button>
                    </div>
                    <div className='grid lg:grid-cols-1 md:gap-10 bg-[#FFFFFF] rounded-lg p-8 translate-y-12'>
                        {users.map((user) => (
                            <div
                                key={user.id}
                                className="border rounded-lg p-4 flex flex-col md:flex-row md:justify-between items-start md:items-start"
                            >
                                <div className="">
                                    <div className='flex justify-start items-center space-x-3 '>
                                        <img
                                            src={user.avatar}
                                            alt={`${user.name}'s avatar`}
                                            className="w-12 h-12 rounded-full"
                                        />
                                        <h3 className="font-bold text-lg">{user.name}</h3>
                                    </div>

                                    <div>
                                        <div className="mt-4 space-y-1 flex justify-start flex-wrap place-items-center gap-3">
                                            <p className="text-[#202224] text-sm bg-[#F1F4F9] px-3 py-1.5 rounded-md">Role: {user.role}</p>
                                            <p className="text-[#202224] text-sm bg-[#F1F4F9] px-3 py-1.5 rounded-md">Email: {user.email}</p>
                                            <p className="text-[#202224] text-sm bg-[#F1F4F9] px-3 py-1.5 rounded-md">Password: {user.password}</p>
                                        </div>
                                        <h2 className='font-semibold mt-4'>Access</h2>

                                        <div className="flex flex-wrap gap-2 mt-4 md:mt-0 space-y-2 md:space-y-0 md:space-x-2">
                                            <>
                                                {user?.permissions?.map((item, index) => (
                                                    <span
                                                        key={index}
                                                        className="bg-[#715EFE33]/10 text-[#212121] text-sm px-3 py-1.5 rounded-md"
                                                    >
                                                        {item}
                                                    </span>
                                                ))}
                                            </>
                                        </div>
                                    </div>
                                </div>
                                <div className="">

                                </div>
                                <div className="mt-4 md:mt-0 flex space-x-2">
                                    <button className="bg-[#FF3B3033]/20 flex gap-2 justify-center items-center text-red-600 text-sm px-4 py-2 rounded-md hover:bg-red-200">
                                        <RiDeleteBinLine />Remove
                                    </button>
                                    <button className="bg-[#715EFE] flex gap-2 justify-center items-center text-white text-sm px-4 py-2 rounded-md hover:bg-purple-700" onClick={() => handleEditAdmin(user)}>
                                        <TbEdit />
                                        Edit
                                    </button>
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </section>
            {
                isCreateAdminModal && (<CreateModal onCancel={toggleCreateModal} inputData={inputData} handleONChange={handleCreateOnChange} onSubmit={handleCreateSubmit} multiSelectOptions={multiSelectOptions} handleMultiSelectChange={handleMultiSelectChange} error={error} />)
            }
            {
                isAdminEditModal && (<EditModal onCancel={toggleEditModal} inputData={editData} multiSelectOptions={multiSelectOptions} handleONChange={handleAdminEditOnChange} />)
            }
        </div>
    )
}

export default AdminMangment