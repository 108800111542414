import React from 'react'
import InputField from '../../../components/ui/Input'
import TextAreaField from '../../../components/ui/TextArea'

const StatusUpdateModal = ({ onCancel, onSubmit, title, inputData, handleONChange }) => {
    return (
        <div>
            <div>
                <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
                    <div className="bg-white rounded-lg shadow-lg w-[50rem]">
                        <div className='border-b w-full'>
                            <div className="flex justify-between items-center border-b pb-2 p-6">
                                <h3 className="text-lg font-semibold">{title}</h3>
                                <button
                                    className="text-gray-500 text-2xl w-10 h-10 bg-gray-100 rounded-full  hover:text-gray-800"
                                    onClick={onCancel}
                                >
                                    &times;
                                </button>
                            </div>
                        </div>
                        <div className='grid grid-cols-1 gap-4 items-center p-4'>
                            <InputField label={`Campaign Name`} name='Lname' value={inputData?.Lname} onChange={handleONChange} />
                        </div>
                        <div className='w-full p-4'>
                            <TextAreaField label={`Campaign Content`} placeholder={`Write here...`} value={inputData?.campaignContent} onChange={handleONChange} name='campaignContent' />
                        </div>
                        <div className="flex justify-end space-x-3 mt-6 border-t p-4">
                            <button
                                className="px-6 py-2 border border-[#B8B8D2] text-gray-700 rounded hover:bg-gray-300"
                                onClick={onCancel}
                            >
                                Cancel
                            </button>
                            <button
                                className="px-6 py-2 bg-purple-600 text-white rounded hover:bg-purple-700"
                                onClick={onSubmit}
                            >
                                {inputData.isBlocked
                                    ? "Blocked"
                                    : inputData.status
                                        ? "Reject"
                                        : "Approve"}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default StatusUpdateModal