import React, { useState } from 'react'
import InputField from '../../components/ui/Input'
import EditCatModal from './Modal/EditCatModal';


function PreferenceMangment() {
    const categoryArray = ["Music",
        "Designing",
        "Designing",
        "Coding",
        "Travel",
        "Fun",
        "Science",
        "Food",
        "Cinema",
        "History",
        "History",
        "History",
        "History",
        "History",
        "History",
        "History",
        "History",
        "History",
        "History",
        "History",
        "History",]

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState("");
    const [editIndex, setEditIndex] = useState(null);
    const handleCatEdit = (category, index) => {
        setSelectedCategory(category);
        setEditIndex(index);
        setIsModalOpen(true);
    }
    const handleInputChange = (e) => {
        setSelectedCategory(e.target.value);
    };
    return (
        <>
            <section className=' h-52 lg:pt-16 pt-10 bg-gradient-to-r from-[#855DF6] to-[#715EFE] '>
                <div className='2xl:w-11/12 w-10/12 lg:w-11/12 mx-auto'>
                    <h2 className='font-bold md:text-3xl text-xl text-[#fff]'>Preference Management</h2>
                    <div className='grid lg:grid-cols-3 md:gap-10 bg-[#FFFFFF] rounded-lg p-8 translate-y-12'>
                        <div className='border-r md:p-4 p-0'>
                            <h2 className='font-semibold md:text-xl text-md text-black'>Create New Category</h2>
                            <div className='pt-5  p-0'>
                                <InputField label={`Category Name`} placeholder={`Enter Category `} />
                                <button className='bg-[#715EFE] text-white p-2 rounded-md'>Create</button>
                            </div>
                        </div>
                        <div className='col-span-2'>
                            <h2 className='font-semibold md:text-xl text-md text-black'>
                                Created Category
                            </h2>
                            <div className='flex flex-wrap gap-2 mt-2'>
                                {
                                    categoryArray?.map((e, index) => {
                                        return (
                                            <div key={index} className=' bg-[#F1F4F9] border border-[#D8D8D8] rounded-full px-4 py-1 text-sm hover:bg-gray-300 cursor-pointer transition' onClick={() => handleCatEdit(e, index)}>{e}</div>
                                        )
                                    })
                                }
                            </div>

                        </div>
                    </div>
                </div>
            </section>
            {
                isModalOpen && (<EditCatModal setIsModalOpen={setIsModalOpen} selectedCategory={selectedCategory} handleInputChange={handleInputChange} />)
            }
        </>

    )
}

export default PreferenceMangment